import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import AboutPageHeader from '../components/about-page-header'
import { TwoImageTextBlock } from 'crostamollica-components/components'
import { InformationSlider } from '../components/information-slider/information-slider'
import MadeInItaly from '../components/about/made-in-italy'
import { AltogetherItalianWithImage } from '../components/content/altogether-italian-with-image'
import HeadingLeftContentRight from '../components/about/heading-left-content-right'
import { InformationColumn } from '../components/information-column/information-column'
import { bind, debounce } from 'decko'
import { MakeADifference } from '../components/about/make-a-difference'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Hreflang } from 'crostamollica-components/components'

class AboutPage extends Component {
  constructor(props) {
    super(props)

    /**
     * @type {object}
     */
    this.state = {
      mobile: true,
    }
  }

  componentDidMount() {
    this.setMobileState()

    window.addEventListener('resize', this.setMobileState, { passive: true })
  }

  @bind
  @debounce(100)
  setMobileState() {
    this.setState({
      mobile: !window.matchMedia('(min-width: 63.75em)').matches,
    })
  }

  render() {
    const { data, location, pageContext } = this.props
    const pageData = data.prismicAboutPage.data
    const crumbs = pageContext?.breadcrumb?.crumbs

    return (
      <>
        <Hreflang lang={process.env.GATSBY_LANGUAGE} />

        <Seo data={pageData} />

        <AboutPageHeader
          location={location}
          heading={pageData.main_title}
          title={pageData.meta_navigation_title}
          subHeading={pageData.main_title}
          content={pageData.main_description}
          imageOne={pageData.main_image_01}
          imageTwo={pageData.main_image_02}
          crumbs={crumbs}
        />

        <MadeInItaly />

        <TwoImageTextBlock
          heading={pageData.origins_heading}
          subHeading={pageData.origins_sub_heading}
          content={pageData.origins_content}
          imageOne={pageData.origins_image_01}
          imageTwo={pageData.origins_image_02}
          hideStripes={true}
        />

        <AltogetherItalianWithImage />

        <HeadingLeftContentRight
          heading={pageData.modern_italian_heading}
          subHeading={pageData.modern_italian_sub_heading}
          content={pageData.modern_italian_content}
        />

        <section>
          {pageData.about_italy && (
            <InformationSlider
              information={pageData.about_italy}
              id="about-us-information"
            />
          )}
        </section>

        <section>
          <MakeADifference
            heading={pageData.make_a_difference_heading}
            description={pageData.make_a_difference_description}
          />
        </section>

        {pageData.make_a_difference && (
          <section>
            {!this.state.mobile ? (
              <InformationColumn
                information={pageData.make_a_difference}
                id="make-a-difference"
              />
            ) : (
              <InformationSlider
                information={pageData.make_a_difference}
                id="make-a-difference"
              />
            )}
          </section>
        )}
      </>
    )
  }
}
export const query = graphql`
  {
    prismicAboutPage {
      _previewable
      data {
        main_title {
          text
          richText
        }
        main_description {
          text
          richText
        }
        main_image_02 {
          ...Image
        }
        main_image_01 {
          ...Image
        }
        origins_content {
          richText
        }
        origins_heading {
          text
          richText
        }
        origins_sub_heading {
          text
          richText
        }
        origins_image_01 {
          ...Image
        }
        origins_image_02 {
          ...Image
        }
        about_italy {
          description {
            richText
            text
          }
          heading {
            richText
            text
          }
          image {
            ...Image
          }
          sub_heading {
            richText
            text
          }
        }
        modern_italian_content {
          richText
          text
        }
        modern_italian_heading {
          richText
          text
        }
        modern_italian_sub_heading {
          richText
          text
        }
        make_a_difference {
          description {
            richText
            text
          }
          heading {
            richText
            text
          }
          image {
            ...Image
          }
          link {
            ...Link
          }
          link_text
        }
        make_a_difference_description {
          richText
          text
        }
        make_a_difference_heading {
          richText
          text
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
      }
    }
  }
`
export default withPrismicPreview(AboutPage)
