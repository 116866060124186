import React from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { Heading } from 'crostamollica-components/components'

const HeadingLeftContentRight = ({ heading, subHeading, content }) => (
  <section className="heading-left-content-right">
    <div className="heading-left-content-right__container container container--flex">
      <div className="heading-left-content-right__column heading-left-content-right__column--left">
        <Heading
          className="heading-left-content-right__heading"
          text={heading.text}
          level="2"
        />
        <Heading
          className="heading-left-content-right__sub-heading"
          text={subHeading.text}
          level="2"
        />
      </div>

      <div className="heading-left-content-right__column heading-left-content-right__column--left">
        <RichText field={content.richText} />
      </div>
    </div>
  </section>
)

export default HeadingLeftContentRight
