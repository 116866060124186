import React, { Component } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import { RichText } from '@superrb/gatsby-addons/components'
import { Heading } from 'crostamollica-components/components'
import GatsbyLink from '../../utils/gatsby-link'

export class InformationColumn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      heading: 0,
    }
  }

  componentDidMount() {
    this.attachObserver()
  }

  attachObserver() {
    try {
      const options = {
        rootMargin: '0px',
        threshold: 0.5,
      }

      const observer = new IntersectionObserver(entries => {
        let upperIndex = -1

        entries.forEach(entry => {
          let index = parseInt(entry.target.id.split('section-')[1])

          if (entry.isIntersecting === true) {
            if (index > upperIndex) {
              upperIndex = index
            }
          }
        })

        if (upperIndex !== this.state.index && upperIndex !== -1) {
          this.setState({
            heading: upperIndex,
          })
        }
      }, options)

      const elements = document.querySelectorAll('.information-column__section')
      elements.forEach(element => observer.observe(element))
    } catch (e) {
      // ignore if the API does not exist
      alert(e)
    }
  }

  render() {
    return (
      <div className="information-column">
        <div className="information-column__column-left">
          {this.props.information.map((information, index) => (
            <a
              href={`#section-${index}`}
              className={`information-column__link ${
                this.state.heading === index
                  ? 'information-column__link--active'
                  : ''
              }`}
              key={index}
            >
              <Heading
                text={information.heading.text}
                level="2"
                className={`information-column__heading ${
                  this.state.heading === index ? 'active' : ''
                }`}
              />
            </a>
          ))}
        </div>
        <div className="information-column__column-right">
          {this.props.information.map((information, index) => (
            <section
              className="information-column__section"
              id={`section-${index}`}
              key={index}
            >
              <Image
                image={information.image}
                sizes="(min-width: 45em) 50vw, 100vw"
                className="information-column__image"
              />

              <div className="information-column__information-box">
                {information.description && (
                  <RichText field={information.description.richText} />
                )}

                {information.link && information.link_text && (
                  <GatsbyLink
                    element={information.link}
                    content={information.link_text}
                    className="cta-link"
                  />
                )}
              </div>
            </section>
          ))}
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>
    )
  }
}
