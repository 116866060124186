import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { AltogetherItalianImage } from './altogether-italian-image'

export const AltogetherItalianWithImage = () => {
  return (
    <section className="altogether-italian altogether-italian--with-image">
      <div className="altogether-italian__container container">
        <StaticImage
          className="altogether-italian__image"
          src="../../assets/img/breadsticks.png"
          formats={['png', 'webp', 'avif']}
        />
        <figure className="altogether-italian__logo">
          <AltogetherItalianImage />
          <figcaption>
            <span>Altogether Italian</span>
          </figcaption>
          <div className="altogether-italian__stripe"></div>
        </figure>
      </div>
    </section>
  )
}
