import { RichText } from '@superrb/gatsby-addons/components'
import React from 'react'
import { Image } from '@superrb/gatsby-addons/components'

const Fact = ({ fact, className, ...props }) => (
  <aside className={`${className} product-fact`} {...props}>
    <div className="product-fact__content">
      <h5 className="product-fact__title">{fact.title.text}</h5>

      <div className="product-fact__text">
        <RichText field={fact.text.richText} />
      </div>
    </div>

    <figure className="product-fact__image">
      <Image image={fact.image} />
    </figure>
  </aside>
)

export default Fact
