exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../../../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */),
  "component---src-templates-legal-page-template-js": () => import("./../../../src/templates/legal-page-template.js" /* webpackChunkName: "component---src-templates-legal-page-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/product-page-template.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-templates-product-type-page-template-js": () => import("./../../../src/templates/product-type-page-template.js" /* webpackChunkName: "component---src-templates-product-type-page-template-js" */),
  "component---src-templates-recipe-page-template-js": () => import("./../../../src/templates/recipe-page-template.js" /* webpackChunkName: "component---src-templates-recipe-page-template-js" */)
}

