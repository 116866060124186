import React from 'react'
import { Heading } from 'crostamollica-components/components'
import { RichText } from '@superrb/gatsby-addons/components'
import { Image } from '@superrb/gatsby-addons/components'

const ArticleSummary = ({ article, key, className = '' }) => {
  const { article_name, article_content, article_image } = article.data

  return (
    <li className={`${className} article`} key={`article-${key}`}>
      {article_image && (
        <figure className="article__image article__image">
          <Image
            image={article_image}
            sizes="(min-width: 63.75em) 25vw, (min-width: 45em) 50vw, 100vw"
          />
        </figure>
      )}

      {article_name && (
        <Heading
          className="article__heading"
          text={article_name.text}
          level="4"
        />
      )}

      {article_content && (
        <div className="article__sub-heading">
          <RichText field={article_content.richText} />
        </div>
      )}
    </li>
  )
}

export default ArticleSummary
