import * as React from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { Hreflang, PageHeader } from 'crostamollica-components/components'
import ShopOnlineRetailers from '../components/retailers/shop-online-retailers'
import ShopInStoreRetailers from '../components/retailers/shop-in-store-retailers'
import { GalleryAndReviews } from 'crostamollica-components/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import StoreFinder from '../components/store-finder'

const WhereToBuyPage = ({ data, location, pageContext }) => {
  const pageData = data.prismicWhereToBuyPage.data
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <PageHeader
        location={location}
        heading={pageData.main_title}
        title={pageData.meta_navigation_title}
        image={pageData.main_image}
        text={pageData.main_description}
        crumbs={crumbs}
      />

      <section className="where-to-buy-container">
        <h2 className="where-to-buy-header">Shop online</h2>
        <ShopOnlineRetailers />

        <div className="where-to-buy-separator">
          <hr />
        </div>
        {/*
         <StoreFinder />
          <div className="where-to-buy-separator">
            <hr />
          </div>
         */}
        <h2 className="where-to-buy-header">Shop in store</h2>
        <ShopInStoreRetailers />
      </section>

      <GalleryAndReviews
        reviews={pageData.reviews}
        photoGallery={pageData.photo_gallery}
      />
    </>
  )
}

export const query = graphql`
  {
    prismicWhereToBuyPage {
      _previewable
      data {
        main_image {
          ...Image
        }
        main_title {
          ...RichText
        }
        main_description {
          ...RichText
        }
        photo_gallery {
          cta_image {
            ...Image
          }
          cta_link {
            ...Link
          }
          cta_link_text
          cta_title {
            ...RichText
          }
          photo_desktop {
            ...Image
          }
          photo_mobile {
            ...Image
          }
          photo_credit
          photo_credit_link
          photo_title {
            ...RichText
          }
          cta_position_desktop_left
          cta_position_desktop_top
          cta_position_mobile_left
          cta_position_mobile_top
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        reviews {
          review {
            uid
            document {
              ... on PrismicReview {
                uid
                data {
                  name {
                    ...RichText
                  }
                  review {
                    ...RichText
                  }
                  stars
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(WhereToBuyPage)
