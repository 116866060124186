import React from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { animator } from '@superrb/gatsby-addons/utils'

const AnimatedUnderline = ({ word }) => {
  return (
    <span className="make-a-difference__word" ref={animator}>
      {word}
    </span>
  )
}

export class DifferenceHeader extends React.Component {
  render() {
    let { text, word, ...props } = this.props

    text = this.replaceWord(text, word)

    const element = 'span'

    return React.createElement(element, props, null, text)
  }

  replaceWord(text, word) {
    let i = 0
    if (text && typeof text === 'string') {
      text = text.split(word).flatMap(part => {
        return [
          part,
          <AnimatedUnderline word={word} key={`difference-part-${i++}`} />,
        ]
      })

      text.pop()
    }

    return text
  }
}

export const MakeADifference = ({ heading, description }) => (
  <section className="make-a-difference">
    <div className="make-a-difference__container container">
      <DifferenceHeader
        text={heading.text}
        word="good"
        className="make-a-difference__heading"
      />

      <RichText field={description.richText} />
    </div>
  </section>
)
