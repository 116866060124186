import React from 'react'

const Close = () => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <g filter="url(#filter1_d)">
        <circle cx="27" cy="27" r="18" fill="white" />
        <circle cx="27" cy="27" r="18" stroke="#0E0E0E" stroke-width="2" />
      </g>
      <path
        d="M25.6358 27.1037L20.4999 32.2396L21.7576 33.4974L26.8935 28.3615L25.6358 27.1037ZM27.1031 25.6364L28.3609 26.8941L33.4968 21.7583L32.239 20.5005L27.1031 25.6364Z"
        fill="#0E0E0E"
      />
      <path
        d="M28.3642 27.1037L27.1065 28.3615L32.2424 33.4974L33.5001 32.2396L28.3642 27.1037ZM26.8969 25.6364L21.761 20.5005L20.5032 21.7583L25.6391 26.8941L26.8969 25.6364Z"
        fill="#0E0E0E"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="4"
        y="4"
        width="46"
        height="46"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="0"
        y="0"
        width="54"
        height="54"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="4"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.00 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default Close
