import React from 'react'
import { Link } from 'gatsby'
import { Heading } from 'crostamollica-components/components'
import { Image } from '@superrb/gatsby-addons/components'

const ArticleSummary = ({ article, className = '' }) => {
  const {
    category,
    meta_navigation_title,
    title,
    cook_time = 0,
    prep_time = 0,
    main_image,
  } = article.data

  const url = '/journal/' + article.uid
  const articleTitle = meta_navigation_title
    ? meta_navigation_title
    : title.text

  const time_required = cook_time + prep_time || null

  return (
    <li className={`${className} recipe`} key={article.uid}>
      <Link to={url} className="recipe__link" activeClassName="active">
        <div className="recipe__image-container">
          {main_image && (
            <figure className="recipe__image recipe__image--primary">
              <Image image={main_image} sizes="(min-width: 45em) 50vw, 100vw" />
            </figure>
          )}
        </div>

        {category?.document?.data && (
          <span className="recipe__category">
            {category.document.data?.name}
          </span>
        )}

        {title && <h4 className="recipe__heading">{articleTitle}</h4>}

        {time_required && (
          <div className="recipe__time-required">
            Time required: {time_required} mins
          </div>
        )}
      </Link>

      <Link
        to={url}
        className="recipe__text-link"
        activeClassName="active"
        aria-label={`Read ${articleTitle}`}
      >
        Read more
      </Link>
    </li>
  )
}

export default ArticleSummary
