import React from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { Heading } from 'crostamollica-components/components'
import { Image } from '@superrb/gatsby-addons/components'
import { Stripes } from 'crostamollica-components/assets'
import { PrismicLink } from 'crostamollica-components/utils'
import { bind, debounce } from 'decko'

export default class JournalPageHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: true,
    }
  }

  componentDidMount() {
    this.setMobileState()
    window.addEventListener('resize', this.setMobileState, { passive: true })
  }

  @bind
  @debounce(100)
  setMobileState() {
    this.setState({
      mobile: !window.matchMedia('(min-width: 30em)').matches,
    })
  }

  render() {
    const { category, heading, author, title, crumbs, image, date } = this.props

    const dateFormat = new Date(date)
    const dateLong = dateFormat.toLocaleString('en', { dateStyle: 'long' })

    return (
      <section className="journal-page-header">
        <div className="journal-page-header__container container container--flex">
          <div className="journal-page-header__breadcrumbs">
            {!this.state.mobile && crumbs && (
              <Breadcrumb
                crumbs={crumbs}
                crumbLabel={title ? title : heading.text}
              />
            )}
          </div>
          <div className="journal-page-header__title">
            <div className="journal-page-header__inner">
              <div className="journal-page-header__inner-category">
                {category && <span>{category.data.name}</span>}
              </div>
              {heading && (
                <Heading
                  className="journal-page-header__heading"
                  text={heading.text}
                  level="1"
                />
              )}
              <div className="journal-page-header__inner-details">
                <span className="journal-page-header__inner-details-author">
                  {' '}
                  By {author}{' '}
                </span>
                |
                <span className="journal-page-header__inner-details-date">
                  {' '}
                  Published:
                  {dateLong && date && (
                    <span className="date-not-bold"> {dateLong}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="journal-page-header__image">
              {image && (
                <figure className="journal-page-header__image-image">
                  <Image image={image} sizes="(min-width: 45em) 50vw, 100vw" />
                </figure>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
