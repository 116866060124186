import React from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import ProductPageHeader from '../components/products/product-page-header'
import ProductIngredients from '../components/products/product-ingredients'
import FeaturedProducts from '../components/content/featured-products'
import { GiantText, Hreflang } from 'crostamollica-components/components'
import RelatedArticles from '../components/content/related-articles'
import ProductSubNav from '../components/products/product-sub-nav'
import { TwoImageTextBlock } from 'crostamollica-components/components'
import CtaLink from '../components/cta-link'
import Fact from '../components/products/fact'
import { Helmet } from 'react-helmet'
import getContrastingTextColor from '../helpers/get-contrasting-text-color'
import { ScrollingBanner } from 'crostamollica-components/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const ProductPageTemplate = ({ data, location, pageContext }) => {
  const pageData = data.prismicProductPage.data
  const parentUrl = '/products/' + pageData.product_type.uid
  const parentName = pageData.product_type.document.data.meta_navigation_title
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <>
      {pageData.highlight_color && (
        <Helmet>
          <style>{`
          :root {
            --page-highlight-color: ${pageData.highlight_color};
            --page-highlight-text-color: ${getContrastingTextColor(
              pageData.highlight_color,
            )};
          }
        `}</style>
        </Helmet>
      )}

      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <ProductPageHeader
        location={location}
        heading={pageData.product_name}
        title={pageData.meta_navigation_title}
        subHeading={pageData.product_sub_heading}
        flags={pageData.flags.map(flag => ({ uid: flag?.flag?.document?.uid, ...flag?.flag?.document?.data }))}
        content={pageData.product_content}
        imageOne={pageData.product_image_01}
        imageTwo={pageData.product_image_02}
        retailers={pageData.retailers}
        parentUrl={parentUrl}
        parentName={parentName}
        crumbs={crumbs}
        awards={pageData.award_groups}
      />

      <ProductSubNav
        showQualityAndPackaging={
          pageData.related_article_01?.document ||
          pageData.related_article_02?.document
        }
      />

      {pageData.discover.length > 0 && (
        <TwoImageTextBlock
          id="discover"
          heading={pageData.discover[0].heading}
          subHeading={pageData.discover[0].sub_heading}
          content={pageData.discover[0].content}
          additionalContent={
            pageData.fact?.document && (
              <Fact fact={pageData.fact?.document?.data} />
            )
          }
          imageOne={pageData.discover[0].image_01}
          imageTwo={pageData.discover[0].image_02}
          link={<CtaLink to={'/about'}>Learn More</CtaLink>}
          reverse={true}
        />
      )}

      <ScrollingBanner />

      {(pageData.related_article_01?.document ||
        pageData.related_article_02?.document) && (
        <RelatedArticles
          articleOne={pageData.related_article_01?.document}
          articleTwo={pageData.related_article_02?.document}
        />
      )}

      <GiantText content={pageData.large_text_graphic?.document?.data?.svg} />

      <ProductIngredients
        ingredients={pageData.product_ingredients}
        nutritionalInformation={pageData.product_nutritional_information}
        portionInformation={pageData.product_portion_information}
      />

      {(pageData.featured_product_01?.document ||
        pageData.featured_product_02?.document) && (
        <FeaturedProducts
          id="explore"
          heading={'Explore our other products'}
          productOne={pageData.featured_product_01?.document}
          productTwo={pageData.featured_product_02?.document}
        />
      )}
    </>
  )
}

export const query = graphql`
  query GetProduct($slug: String!) {
    prismicProductPage(uid: { eq: $slug }) {
      _previewable
      data {
        product_type {
          uid
          document {
            ... on PrismicProductTypePage {
              id
              data {
                product_type_name {
                  text
                }
                meta_navigation_title
              }
            }
          }
        }
        product_name {
          text
          richText
        }
        product_sub_heading {
          text
          richText
        }
        product_content {
          richText
        }
        flags {
          flag {
            document {
              ... on PrismicProductFlag {
                uid
                data {
                  title
                  icon {
                    ...Image
                  }
                }
              }
            }
          }
        }
        product_image_01 {
          ...Image
        }
        product_image_02 {
          ...Image
        }
        fact {
          document {
            ... on PrismicFact {
              data {
                title {
                  text
                }
                text {
                  text
                  richText
                }
                image {
                  ...Image
                }
              }
            }
          }
        }
        retailers {
          custom_link {
            ...Link
          }
          retailer {
            document {
              ... on PrismicRetailer {
                id
                data {
                  website_address {
                    url
                  }
                  logo {
                    ...Image
                  }
                }
              }
            }
          }
        }
        discover {
          heading {
            text
          }
          sub_heading {
            text
          }
          content {
            richText
          }
          image_01 {
            ...Image
          }
          image_02 {
            ...Image
          }
        }
        related_article_01 {
          uid
          document {
            ... on PrismicArticle {
              id
              data {
                article_name {
                  text
                }
                article_content {
                  richText
                }
                article_image {
                  ...Image
                }
              }
            }
          }
        }
        related_article_02 {
          uid
          document {
            ... on PrismicArticle {
              id
              data {
                article_name {
                  text
                }
                article_content {
                  richText
                }
                article_image {
                  ...Image
                }
              }
            }
          }
        }
        large_text_graphic {
          document {
            ... on PrismicLargeTextGraphic {
              id
              data {
                svg {
                  ...Image
                }
              }
            }
          }
        }
        product_ingredients {
          richText
        }
        product_nutritional_information {
          column_01 {
            text
          }
          column_02 {
            text
          }
          column_03 {
            text
          }
        }
        product_portion_information {
          richText
        }
        featured_product_01 {
          uid
          document {
            ... on PrismicProductPage {
              uid
              data {
                product_type {
                  uid
                }
                product_name {
                  richText
                  text
                }
                product_sub_heading {
                  text
                  richText
                }
                product_image_01 {
                  ...Image
                }
                product_image_02 {
                  ...Image
                }
              }
            }
          }
        }
        featured_product_02 {
          uid
          document {
            ... on PrismicProductPage {
              uid
              data {
                product_type {
                  uid
                }
                product_name {
                  richText
                  text
                }
                product_sub_heading {
                  text
                  richText
                }
                product_image_01 {
                  ...Image
                }
                product_image_02 {
                  ...Image
                }
              }
            }
          }
        }
        highlight_color
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        award_groups {
          awards {
            document {
              ... on PrismicAwards {
                id
                data {
                  award_image {
                    ...Image
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ProductPageTemplate)
