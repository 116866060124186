import React, { Component, useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { Hreflang, PageHeader } from 'crostamollica-components/components'
import { TwoImageTextBlock } from 'crostamollica-components/components'
import GatsbyLink from '../utils/gatsby-link'
import { GalleryAndReviews } from 'crostamollica-components/components'
import ProductTypeScrollingBanner from '../components/products/product-type-scrolling-banner'
import { GiantText } from 'crostamollica-components/components'
import { FeaturedProductsHome } from '../components/featured-products/featured-products'
import { AltogetherItalian } from '../components/content/altogether-italian'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { useAlternates } from 'crostamollica-components/hooks'

const IndexPage = ({ data, location }) => {
  const [largeTextGraphic, setLargeTextGraphic] = useState(null)

  const nodes = data?.allPrismicLargeTextGraphic?.nodes
  useEffect(() => {
    if (nodes) {
      setLargeTextGraphic(
        nodes[Math.floor(Math.random() * nodes.length)].data.svg
      )
    }
  }, [nodes])

  const pageData = data.prismicHomepage.data

  useAlternates(pageData.languages)

  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <PageHeader
        video={pageData?.main_video_link?.url}
        location={location}
        heading={pageData.main_title}
        image={pageData.main_image}
        link={
          <GatsbyLink
            element={pageData.main_link}
            content={pageData.main_link_text}
            className="cta-link"
          />
        }
      />

      <div className="pseudo-elements">
        <TwoImageTextBlock
          heading={pageData.our_story_heading}
          subHeading={pageData.our_story_sub_heading}
          content={pageData.our_story_content}
          imageOne={pageData.our_story_image_01}
          imageTwo={pageData.our_story_image_02}
          link={
            <GatsbyLink
              element={pageData.our_story_link}
              content={pageData.our_story_link_text}
              className="cta-link"
            />
          }
        />
      </div>

      <ProductTypeScrollingBanner />

      <FeaturedProductsHome
        products={pageData.featured_products}
        id="featured_products_home"
      />

      <AltogetherItalian />

      <TwoImageTextBlock
        heading={pageData.our_manifesto_heading}
        subHeading={pageData.our_manifesto_sub_heading}
        content={pageData.our_manifesto_content}
        imageOne={pageData.our_manifesto_image_01}
        imageTwo={pageData.our_manifesto_image_02}
        link={
          <GatsbyLink
            element={pageData.our_manifesto_link}
            content={pageData.our_manifesto_link_text}
            className="cta-link"
          />
        }
      />

      <div className="large-text-bolli">
        {largeTextGraphic && <GiantText content={largeTextGraphic} />}
      </div>

      <GalleryAndReviews
        reviews={pageData.reviews}
        photoGallery={pageData.photo_gallery}
      />
    </>
  )
}

export const query = graphql`
  {
    prismicHomepage {
      data {
        main_image {
          ...Image
        }
        main_title {
          text
          richText
        }
        main_link {
          ...Link
        }
        main_video_link {
          ...Link
        }
        main_link_text
        our_story_content {
          richText
        }
        our_story_heading {
          text
          richText
        }
        our_story_sub_heading {
          text
          richText
        }
        our_story_link {
          ...Link
        }
        our_story_link_text
        our_story_image_01 {
          ...Image
        }
        our_story_image_02 {
          ...Image
        }
        our_manifesto_content {
          richText
        }
        our_manifesto_heading {
          text
          richText
        }
        our_manifesto_sub_heading {
          text
          richText
        }
        our_manifesto_link {
          ...Link
        }
        our_manifesto_link_text
        our_manifesto_image_01 {
          ...Image
        }
        our_manifesto_image_02 {
          ...Image
        }
        photo_gallery {
          cta_image {
            ...Image
          }
          cta_link {
            ...Link
          }
          cta_link_text
          cta_title {
            richText
            text
          }
          photo_desktop {
            ...Image
          }
          photo_mobile {
            ...Image
          }
          photo_credit
          photo_credit_link
          photo_title {
            richText
            text
          }
          cta_position_desktop_left
          cta_position_desktop_top
          cta_position_mobile_left
          cta_position_mobile_top
        }
        featured_products {
          featured_product {
            uid
            document {
              ... on PrismicProductPage {
                id
                data {
                  product_image_01 {
                    ...Image
                  }
                  product_image_02 {
                    ...Image
                  }
                  product_name {
                    richText
                    text
                  }
                  product_content {
                    richText
                    text
                  }
                  product_type {
                    uid
                    document {
                      ... on PrismicProductTypePage {
                        id
                        data {
                          product_type_name {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        languages {
          locale
          link {
            url
          }
        }
        reviews {
          review {
            uid
            document {
              ... on PrismicReview {
                uid
                data {
                  name {
                    text
                    richText
                  }
                  review {
                    text
                    richText
                  }
                  stars
                }
              }
            }
          }
        }
      }
    }
    allPrismicLargeTextGraphic(
      filter: { data: { appears_on_homepage: { eq: true } } }
    ) {
      nodes {
        data {
          svg {
            ...Image
          }
        }
      }
    }
  }
`

export default withPrismicPreview(IndexPage)
