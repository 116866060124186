import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Image } from '@superrb/gatsby-addons/components'

const ProductTypeScrollingBanner = () => {
  const data = useStaticQuery(graphql`
    query ProductTypeScrollingBannerQuery {
      allPrismicProductTypePage(sort: { data: { display_order: ASC } }) {
        nodes {
          uid
          data {
            product_type_name {
              richText
              text
            }
            product_type_svg {
              ...Image
            }
          }
        }
      }
    }
  `)
  return (
    <section className="scrolling-banner scrolling-banner--product-types">
      <div className="scrolling-banner__container container">
        <ul className="scrolling-banner__list">
          {[...Array(3)].map((_, i) => {
            return data.allPrismicProductTypePage.nodes.map(
              (productType, index) => {
                const url = '/products/' + productType.uid
                return (
                  <li
                    className="scrolling-banner__list-item"
                    key={productType.uid}
                    aria-hidden={(i !== 0).toString()}
                  >
                    <a
                      href={url}
                      title={productType.data.product_type_name.text}
                    >
                      {productType.data.product_type_svg && (
                        <Image image={productType.data.product_type_svg} />
                      )}
                    </a>
                  </li>
                )
              },
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default ProductTypeScrollingBanner
