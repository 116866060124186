import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

class OurProductsNavigationTitle extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query OurProductsNavigationTitleQuery {
            allPrismicProductTypeIndexPage {
              nodes {
                data {
                  meta_navigation_title
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.allPrismicProductTypeIndexPage.nodes.map((page, index) => {
              return <>{page.data.meta_navigation_title}</>
            })}
          </>
        )}
      />
    )
  }
}
export default OurProductsNavigationTitle
