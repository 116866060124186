import React, { Component } from 'react'
import { createContext } from 'react'

export const navOpen = {
  'where-to-buy-popup': false,
  'product-subnav': false,
  'main-nav': false,
}

export const toggleNav = function toggleNav(target) {
  this.setState(state => ({
    ...state,
    navOpen: {
      ...state.navOpen,
      [target]: !state.navOpen[target],
    },
  }))
}

export const closeNav = function closeNav(target) {
  if (this === undefined) {
    return
  }

  this.setState(state => ({
    ...state,
    navOpen: {
      ...state.navOpen,
      [target]: false,
    },
  }))
}

export const NavOpenContext = createContext({
  navOpen,
  toggleNav,
  closeNav,
})

export class Provider extends Component {
  constructor(props) {
    super(props)

    this.props = props
    this.state = {
      navOpen,
      toggleNav: toggleNav.bind(this),
      closeNav: closeNav.bind(this),
    }
  }

  componentDidMount() {
    window.navOpenProvider = this
  }

  render() {
    return (
      <NavOpenContext.Provider value={this.state}>
        {this.props.children}
      </NavOpenContext.Provider>
    )
  }
}
