import React from 'react'
import Italy from '../../assets/icons/italy.svg.js'
import MadeInItalyGraphic from '../../assets/icons/made-in-italy.svg'
import { animator } from '@superrb/gatsby-addons/utils'

const MadeInItaly = () => (
  <section className="made-in-italy" ref={animator}>
    <div className="made-in-italy__container container container--flex">
      <Italy className="made-in-italy__italy" />
      <MadeInItalyGraphic className="made-in-italy__text" />
    </div>
  </section>
)

export default MadeInItaly
