import { Link } from 'gatsby'
import React from 'react'
import ArticleSummary from './article-summary'

const RelatedArticles = ({ articles }) => {
  if (!articles) return null

  return (
    <section className="related-recipes">
      <div className="related-recipes__container container">
        <div className="related-recipes__header">
          <h2 className="related-recipes__title">Continue Reading</h2>

          <Link to="/journal" className="related-recipes__all">
            View all articles
          </Link>
        </div>

        <ul className="related-recipes__recipes">
          {articles.map(article => (
            <ArticleSummary
              article={article}
              className="related-recipes__recipe"
            />
          ))}
        </ul>
      </div>
    </section>
  )
}

export default RelatedArticles
