import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import CtaLink from '../cta-link'
import MenuToggle from '../nav/menu-toggle'
import { bind, debounce } from 'decko'
import { Image } from '@superrb/gatsby-addons/components'
import { NavOpenContext } from '../context/nav-open-context'

export default class ProductTypeNavigation extends Component {
  constructor(props) {
    super(props)

    /**
     * @type {string}
     */
    this.id = 'product-subnav'

    /**
     * @type {object}
     */
    this.state = {
      mobile: true,
      imageVisibility: {},
    }
  }

  componentDidMount() {
    this.setMobileState()

    window.addEventListener('resize', this.setMobileState, { passive: true })
  }

  @bind
  @debounce(100)
  setMobileState() {
    this.setState({
      mobile: !window.matchMedia('(min-width: 63.75em)').matches,
    })
  }

  @bind
  setImageVisibility(uid, value) {
    const { imageVisibility } = this.state
    imageVisibility[uid] = value

    this.setState({ imageVisibility })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query ProductTypeNavigationQuery {
            allPrismicProductTypePage(sort: { data: { display_order: ASC } }) {
              nodes {
                uid
                data {
                  meta_navigation_title
                  product_type_image {
                    ...Image
                  }
                  featured_product {
                    document {
                      ... on PrismicProductPage {
                        data {
                          product_image_01 {
                            ...Image
                          }
                          product_image_02 {
                            ...Image
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <NavOpenContext.Consumer>
            {({ navOpen, toggleNav }) => (
              <nav
                className="product-subnav"
                id={this.id}
                aria-hidden={!navOpen[this.id]}
              >
                <div className="product-subnav__container container">
                  <MenuToggle
                    target="product-subnav"
                    className={`product-subnav__${
                      this.state.mobile ? 'back' : 'close'
                    }`}
                    label={
                      this.state.mobile ? (
                        <span className="screenreader-text">
                          Back to main menu
                        </span>
                      ) : (
                        <div className="product-subnav__close-text">
                          <span>Close</span>
                        </div>
                      )
                    }
                  />

                  <h2 className="product-subnav__title">Product range</h2>

                  <ul className="product-subnav__list">
                    {data.allPrismicProductTypePage.nodes.map(
                      (productType, index) => {
                        const url = '/products/' + productType.uid
                        return (
                          <li className="product-subnav__item" key={index}>
                            <Link
                              to={url}
                              className="product-subnav__link"
                              activeClassName="product-subnav__link--active"
                              onMouseEnter={this.setImageVisibility.bind(
                                this,
                                productType.uid,
                                true,
                              )}
                              onMouseLeave={this.setImageVisibility.bind(
                                this,
                                productType.uid,
                                false,
                              )}
                            >
                              {productType.data.meta_navigation_title}
                            </Link>
                          </li>
                        )
                      },
                    )}
                  </ul>

                  <CtaLink to="/products" className="product-subnav__all">
                    All Products
                  </CtaLink>

                  {data.allPrismicProductTypePage.nodes.map(
                    (productType, key) => {
                      const productData =
                        productType.data?.featured_product?.document?.data

                      if (!productData) {
                        return
                      }

                      return (
                        <div
                          id={`product-subnav-image__${productType.uid}`}
                          className="product-subnav__product-type-images"
                          key={key}
                          aria-hidden={
                            !this.state.imageVisibility[productType.uid]
                          }
                        >
                          {productType.data.product_type_image && (
                            <figure className="product-subnav__product-type-image product-subnav__product-type-image--primary">
                              <Image
                                image={productType.data.product_type_image}
                              />
                            </figure>
                          )}
                        </div>
                      )
                    },
                  )}
                </div>
              </nav>
            )}
          </NavOpenContext.Consumer>
        )}
      />
    )
  }
}
