import React from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { Hreflang, PageHeader } from 'crostamollica-components/components'
import RecipeListing from '../components/recipes/recipe-listing'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const Recipes = ({ data, location, pageContext }) => {
  const pageData = data.page.data
  const recipes = data.recipes.nodes
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <PageHeader
        location={location}
        heading={pageData.title}
        title={pageData.meta_navigation_title}
        text={pageData.description}
        image={pageData.image}
        crumbs={crumbs}
      />

      <RecipeListing recipes={recipes} />
    </>
  )
}
export const query = graphql`{
  page: prismicRecipesIndexPage {
    data {
      title {
        text
        richText
      }
      description {
        richText
      }
      image {
        ...Image
      }
      meta_description
      meta_robots
      meta_title
      meta_navigation_title
      meta_image {
        ...Image
      }
    }
  }
  recipes: allPrismicRecipePage(sort: {first_publication_date: DESC}) {
    nodes {
      uid
      data {
        title {
          richText
          text
        }
        cook_time
        prep_time
        main_image {
          ...Image
        }
        category {
          document {
            ... on PrismicRecipeCategory {
              uid
              data {
                name
              }
            }
          }
        }
        featured_product {
          document {
            ... on PrismicProductPage {
              uid
            }
          }
        }
      }
    }
  }
}`

export default withPrismicPreview(Recipes)
