import React from 'react'
import ProductAutocomplete from '../products/product-autocomplete'

const RecipeToolbar = ({
  categories = [],
  selectedCategory = null,
  products = [],
  selectedProduct = null,
  onFilterChange = () => {},
}) => {
  return (
    <div className="toolbar toolbar--recipes">
      <label className="toolbar__label">
        <span className="toolbar__label-text">Filter by category</span>

        <ul className="toolbar__list">
          <li className="toolbar__item" key={'all'}>
            <button
              type="button"
              role="switch"
              aria-checked={
                !selectedCategory || selectedCategory === 'all'
                  ? 'true'
                  : 'false'
              }
              onClick={() => onFilterChange('category', 'all')}
            >
              All
            </button>
          </li>

          {categories.map(category => (
            <li className="toolbar__item" key={category.uid}>
              <button
                type="button"
                role="switch"
                aria-checked={
                  selectedCategory === category.uid ? 'true' : 'false'
                }
                onClick={() => onFilterChange('category', category.uid)}
              >
                {category.data.name}
              </button>
            </li>
          ))}
        </ul>
      </label>

      <label htmlFor="filter-products" className="toolbar__label">
        <span className="toolbar__label-text">Filter by </span>

        <ProductAutocomplete
          selectedProduct={selectedProduct}
          onChange={value => onFilterChange('product', value)}
        />
      </label>
    </div>
  )
}

export default RecipeToolbar
