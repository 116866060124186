import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { RichText } from '@superrb/gatsby-addons/components'
import { Seo } from '@superrb/gatsby-addons/components'
import LegalPageNavigation from '../components/legal-page-navigation'
import { Heading, Hreflang } from 'crostamollica-components/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { useAlternates } from 'crostamollica-components/hooks'

const LegalPageTemplate = ({ data, location, pageContext }) => {
  const pageData = data.prismicLegalPage.data
  const crumbs = pageContext?.breadcrumb?.crumbs

  useAlternates(data?.prismicLegalPage?.data?.languages)

  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <section className="container">
        <div className="legal-page-header">
          <div className="legal-page">
            {crumbs && (
              <Breadcrumb
                crumbs={crumbs}
                crumbLabel={
                  pageData.meta_navigation_title
                    ? pageData.meta_navigation_title
                    : pageData.page_title.text
                }
              />
            )}

            <div className="legal-page__container">
              <div className="legal-page__header">
                <Heading text={pageData.page_title.text} level="1" />

                <ul>
                  <LegalPageNavigation />
                </ul>
              </div>

              <hr />

              <div className="legal-page__content">
                <RichText field={pageData.page_content.richText} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query GetLegalPage($slug: String!) {
    prismicLegalPage(uid: { eq: $slug }) {
      _previewable
      data {
        page_content {
          richText
        }
        page_title {
          richText
          text
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        languages {
          locale
          link {
            url
          }
        }
      }
    }
  }
`

export default withPrismicPreview(LegalPageTemplate)
