import React, { Component } from 'react'
import qs from 'query-string'

export default class ProductToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterBy: '',
      sortBy: '',
    }
  }

  componentDidMount() {
    const queryString = qs.parse(window.location.search)

    this.setState({
      filterBy: queryString.filterBy,
      sortBy: queryString.sortBy,
    })
  }

  render() {
    const {
      filterFields = {},
      onFilterChange,
      sortFields = {},
      onSortChange,
      count,
    } = this.props

    return (
      <div className="product-toolbar">
        <select
          name="filter"
          className="product-toolbar__control"
          onChange={function (event) {
            onFilterChange(event.target.value)
          }}
        >
          {Object.entries(filterFields).map(([key, label]) => (
            <option value={key} key={key} selected={key === this.state.filterBy}>
              {label}
            </option>
          ))}
        </select>

        <select
          name="sort"
          className="product-toolbar__control"
          onChange={function (event) {
            onSortChange(event.target.value)
          }}
        >
          {Object.entries(sortFields).map(([key, label]) => (
            <option value={key} key={key} selected={this.state.sortBy}>
              {label}
            </option>
          ))}
        </select>
        <span className="product-toolbar__count">Showing {count} items</span>
      </div>
    )
  }
}
