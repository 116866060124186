import React from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { Hreflang, PageHeader } from 'crostamollica-components/components'
import ProductListing from '../components/products/product-listing'
import { GalleryAndReviews } from 'crostamollica-components/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import ProductSummary from '../components/products/product-summary'

const ProductTypePageTemplate = ({ data, location, pageContext }) => {
  const pageData = data.prismicProductTypePage.data
  const productPages = data.allPrismicProductPage.nodes
  const crumbs = pageContext?.breadcrumb?.crumbs
  const productArray = []
  pageData.product_listing.forEach((product, index) => {
    productArray.push(product.product_link.document)
  })
  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <PageHeader
        location={location}
        heading={pageData.product_type_name}
        title={pageData.meta_navigation_title}
        text={pageData.product_type_description}
        image={pageData.product_type_image}
        crumbs={crumbs}
      />

      <ProductListing products={productArray} />

      <GalleryAndReviews
        reviews={pageData.reviews}
        photoGallery={pageData.photo_gallery}
      />
    </>
  )
}

export const query = graphql`
  query GetProductType($slug: String!) {
    prismicProductTypePage(uid: { eq: $slug }) {
      _previewable
      data {
        product_type_name {
          text
          richText
        }
        product_type_description {
          text
          richText
        }
        product_type_image {
          ...Image
        }
        product_listing {
          product_link {
            uid
            document {
              ... on PrismicProductPage {
                uid
                data {
                  meta_navigation_title
                  product_type {
                    uid
                  }
                  product_name {
                    richText
                    text
                  }
                  product_sub_heading {
                    text
                    richText
                  }
                  product_image_01 {
                    ...Image
                  }
                  product_image_02 {
                    ...Image
                  }
                  flags {
                    flag {
                      document {
                        ... on PrismicProductFlag {
                          uid
                          data {
                            title
                            icon {
                              ...Image
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        photo_gallery {
          cta_image {
            ...Image
          }
          cta_link {
            ...Link
          }
          cta_link_text
          cta_title {
            richText
            text
          }
          photo_desktop {
            ...Image
          }
          photo_mobile {
            ...Image
          }
          photo_credit
          photo_credit_link
          photo_title {
            richText
            text
          }
          cta_position_desktop_left
          cta_position_desktop_top
          cta_position_mobile_left
          cta_position_mobile_top
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        reviews {
          review {
            uid
            document {
              ... on PrismicReview {
                uid
                data {
                  name {
                    text
                    richText
                  }
                  review {
                    text
                    richText
                  }
                  stars
                }
              }
            }
          }
        }
      }
    }
    allPrismicProductPage(
      filter: { data: { product_type: { uid: { eq: $slug } } } }
    ) {
      nodes {
        uid
        data {
          meta_navigation_title
          product_type {
            uid
          }
          product_name {
            richText
            text
          }
          product_sub_heading {
            text
            richText
          }
          product_image_01 {
            ...Image
          }
          product_image_02 {
            ...Image
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ProductTypePageTemplate)
