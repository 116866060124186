import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Retailer from './retailer'

class ShopInStoreRetailers extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`query ShopInStoreRetailersQuery {
  allPrismicRetailer(
    filter: {tags: {eq: "In Store"}}
    sort: {data: {display_order: ASC}}
  ) {
    nodes {
      data {
        display_order
        name {
          html
          richText
          text
        }
        logo {
          ...Image
        }
      }
    }
  }
}`}
        render={data => (
          <section className="where-to-buy-stores-retail">
            <div className="where-to-buy-stores-retail__container">
              {data.allPrismicRetailer.nodes.map((retailer, index) => {
                return (
                  <Retailer retailer={retailer} type="in-store" key={index} />
                )
              })}
            </div>
          </section>
        )}
      />
    );
  }
}

export default ShopInStoreRetailers
