/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { LazyLoadingContextProvider } from '@superrb/gatsby-addons/context'
import { HreflangContextProvider } from 'crostamollica-components/context'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from './src/utils/prismic-previews'

const React = require('react')
const {
  navOpen,
  Provider: NavOpenContextProvider,
  closeNav,
} = require('./src/components/context/nav-open-context')

const Layout = require('./src/components/layout').default

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <HreflangContextProvider>
      <LazyLoadingContextProvider>
        <NavOpenContextProvider>{element}</NavOpenContextProvider>
      </LazyLoadingContextProvider>
    </HreflangContextProvider>
  </PrismicPreviewProvider>
)

export const onRouteUpdate = ({ location: { pathname } }) => {
  if ('navOpenProvider' in window) {
    Object.keys(navOpen).forEach(key =>
      closeNav.call(window.navOpenProvider, key)
    )
  }

  if (pathname === '/') {
    pathname = 'home'
  }

  document.body.setAttribute(
    'class',
    `page ${[
      ...pathname
        .split('/')
        .filter(x => !!x)
        .map(slug => `page--${slug}`),
    ].join(' ')}`
  )
}
