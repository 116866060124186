import { Link } from 'gatsby'
import React, { Component } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import MenuToggle from '../nav/menu-toggle'
import Close from '../../assets/icons/close.svg.js'
import { NavOpenContext } from '../context/nav-open-context'

export default class WhereToBuyPopup extends Component {
  constructor(props) {
    super(props)

    /**
     * @type {string}
     */
    this.id = 'where-to-buy-popup'
  }

  render() {
    const { retailers } = this.props
    const slicedRetailers = retailers.slice(0, 3)

    if (retailers.length === 0) {
      return null
    }

    return (
      <NavOpenContext.Consumer>
        {({ navOpen, toggleNav }) => (
          <div className="product-page-header__where-to-buy where-to-buy">
            <MenuToggle
              className="cta-link where-to-buy__toggle"
              target="where-to-buy-popup"
              label="Where to buy"
            />

            <aside
              className="where-to-buy__popup"
              id="where-to-buy-popup"
              aria-hidden={!navOpen[this.id]}
            >
              <h3 className="where-to-buy__title">Where to buy</h3>

              <ul
                className={
                  'where-to-buy__list ' +
                  (slicedRetailers.length <= 2 ? 'two' : '')
                }
              >
                {slicedRetailers.map((retailer, key) => (
                  <li className="where-to-buy__item" key={key}>
                    <a
                      href={
                        retailer.custom_link?.url
                          ? retailer.custom_link?.url
                          : retailer.retailer.document?.data?.website_address
                              ?.url
                      }
                      className="where-to-buy__link"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      {retailer?.retailer?.document?.data?.logo && (
                        <Image
                          image={retailer.retailer.document.data.logo}
                          className="where-to-buy__logo"
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>

              <Link className="where-to-buy__view-all" to="/where-to-buy">
                View all stockists
              </Link>

              <MenuToggle
                className="where-to-buy__close"
                target="where-to-buy-popup"
                label={<Close />}
              />
            </aside>
          </div>
        )}
      </NavOpenContext.Consumer>
    )
  }
}
