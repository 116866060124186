import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const LegalPageNavigation = () => {
  const data = useStaticQuery(graphql`query LegalPageNavigationQuery {
  allPrismicLegalPage(sort: {last_publication_date: ASC}) {
    nodes {
      data {
        meta_navigation_title
      }
      uid
    }
  }
}`)

  return (
    <>
      {data.allPrismicLegalPage.nodes.map((legalPage, index) => {
        const url = '/legal/' + legalPage.uid

        return (
          <li className="nav__list-item" key={index}>
            <Link
              to={url}
              className="nav__link"
              activeClassName="nav__link--active"
            >
              {legalPage.data.meta_navigation_title}
            </Link>
          </li>
        )
      })}
    </>
  )
}

export default LegalPageNavigation
