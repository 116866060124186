import React, { useCallback, useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import RecipeSummary from './recipe-summary'
import qs from 'query-string'
import RecipeToolbar from './recipe-toolbar'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { animate } from '@superrb/gatsby-addons/utils'

const FILTER_MAP = {
  category: (recipe, category) =>
    !category ||
    category === 'all' ||
    recipe.data?.category?.document?.uid === category,
  product: (recipe, product) =>
    !product ||
    product === 'all' ||
    recipe.data?.featured_product?.document?.uid === product,
}

const RecipeListing = ({ recipes }) => {
  const [filterFields, setFilterFields] = useState({
    category: 'all',
    product: 'all',
  })

  const listingRef = useRef(null)

  useEffect(() => {
    const queryString = qs.parse(window.location.search)
    setFilterFields({
      category: queryString.category || 'all',
      product: queryString.product || 'all',
    })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      categories: allPrismicRecipeCategory {
        nodes {
          uid
          data {
            name
          }
        }
      }
      productTypes: allPrismicProductTypePage {
        nodes {
          data {
            meta_navigation_title
            product_type_name {
              text
            }
          }
          uid
        }
      }
      products: allPrismicProductPage {
        nodes {
          uid
          data {
            meta_navigation_title
            product_name {
              text
            }
            product_type {
              document {
                ... on PrismicProductTypePage {
                  uid
                }
              }
            }
          }
        }
      }
    }
  `)

  const categories = data.categories.nodes
  const products = data.products.nodes
  const availableProducts = recipes.reduce((arr, recipe) => {
    const uid = recipe.data.featured_product.document.uid

    if (uid) {
      arr.push(recipe.data.featured_product.document.uid)
    }

    return arr
  }, [])
  const groupedProducts = data.productTypes.nodes.map(productType => ({
    ...productType,
    products: products.filter(
      product =>
        availableProducts.includes(product.uid) &&
        product.data.product_type.document.uid === productType.uid
    ),
  }))

  const filterBy = useCallback(
    async (field, value) => {
      if (listingRef.current) {
        await animate(1, 0, t => {
          listingRef.current.style.opacity = t
        })
      }
      setFilterFields(fields => {
        // Clone object to force re-render
        const cloned = { ...fields }
        cloned[field] = value

        return cloned
      })

      const queryString = qs.parse(window.location.search)
      queryString[field] = value
      window.history.pushState(
        {},
        null,
        `${window.location.pathname}?${qs.stringify(queryString)}`
      )

      if (listingRef.current) {
        await animate(0, 1, t => {
          listingRef.current.style.opacity = t
        })
      }

      document.activeElement.blur()
    },
    [setFilterFields]
  )

  const filteredRecipes = Object.keys(FILTER_MAP).reduce((recipes, field) => {
    return recipes.filter(recipe =>
      FILTER_MAP[field](recipe, filterFields[field])
    )
  }, recipes)

  return (
    <section className="recipe-listing">
      <div className="recipe-listing__container container">
        <RecipeToolbar
          categories={categories}
          selectedCategory={filterFields.category}
          products={groupedProducts}
          selectedProduct={filterFields.product}
          onFilterChange={filterBy}
        />

        <ul className="recipe-listing__recipes" ref={listingRef}>
          {filteredRecipes.length > 0 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 1024: 3 }}
            >
              <Masonry gutter={`${1.5714285714 * 1.5}em`}>
                {filteredRecipes.map((recipe, index) => (
                  <RecipeSummary
                    className="recipe-listing__recipe"
                    recipe={recipe}
                    key={recipe.uid}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <p className="recipe-listing__empty">No recipes found</p>
          )}
        </ul>
      </div>
    </section>
  )
}

export default RecipeListing
