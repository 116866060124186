import React from 'react'

export const AltogetherItalianImage = () => {
  return (
    <svg
      width="432"
      height="164"
      viewBox="0 0 432 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M407.715 116.046C409.081 116.046 411.812 116.744 412.69 120.134C414.543 128.11 397.864 135.288 397.864 135.288L400.79 139.076C418.737 130.303 431.905 117.143 432.002 99.9953C432.1 85.0409 422.346 73.0774 406.74 73.875C394.84 74.4731 385.574 84.3431 386.159 96.0075C386.647 106.974 395.913 116.046 407.715 116.046Z"
        fill="#0E0E0E"
      />
      <path
        d="M22.8259 25.9211C20.0948 26.0208 18.144 25.622 17.0711 22.4318C14.7302 14.9545 28.7758 6.28099 28.7758 6.28099L26.6299 3.4895C10.1458 13.0603 -1.07113 26.619 0.0993381 42.9691C1.07473 57.2257 11.0237 66.0986 22.8259 66.298C35.9937 66.4974 45.65 55.6305 44.0894 43.667C42.8214 33.1989 33.4577 25.5223 22.8259 25.9211Z"
        fill="#0E0E0E"
      />
      <path
        className="altogether-italian__small-stripe"
        d="M367.335 154.728H57.8438V164H367.335V154.728Z"
        fill="#0E0E0E"
      />
      <path
        d="M68.7678 59.3192L66.9145 63.9052H57.5508L75.3029 23.5283L92.9574 63.9052H83.4961L81.7404 59.3192H68.7678ZM75.3029 43.0687L71.6939 52.0414H78.8143L75.3029 43.0687Z"
        fill="#0E0E0E"
      />
      <path
        d="M118.904 63.9053H97.3477V24.8245H105.541V56.8269H119.391L118.904 63.9053Z"
        fill="#0E0E0E"
      />
      <path
        d="M135.876 32.6008V63.9053H127.488V32.6008H120.367V24.8245H143.581L143.191 32.6008H135.876Z"
        fill="#0E0E0E"
      />
      <path
        d="M164.551 64.304C153.529 64.304 145.043 55.1319 145.043 44.3648C145.043 33.5976 153.529 24.5253 164.551 24.5253C175.573 24.5253 184.059 33.6973 184.059 44.3648C184.059 55.0323 175.475 64.304 164.551 64.304ZM177.036 44.2651C177.036 37.3861 171.574 31.7034 164.746 31.7034C158.016 31.7034 152.456 37.3861 152.456 44.2651C152.456 51.1441 157.918 56.8268 164.746 56.8268C171.574 56.9265 177.036 51.2438 177.036 44.2651Z"
        fill="#0E0E0E"
      />
      <path
        d="M222.295 57.8238C218.979 62.0111 213.127 64.3041 207.957 64.3041C196.447 64.3041 188.449 54.5339 188.449 44.3649C188.449 32.3016 198.301 24.4257 207.957 24.4257C213.127 24.4257 218.979 26.7187 222.295 30.9059L217.321 36.0901C214.98 33.498 211.761 31.8032 208.055 31.8032C201.129 31.8032 195.765 37.2865 195.765 44.3649C195.765 51.4433 201.129 56.9266 208.055 56.9266C210.883 56.9266 213.322 56.0293 215.467 54.4342V44.4646H222.393L222.295 57.8238Z"
        fill="#0E0E0E"
      />
      <path
        d="M227.754 63.9053V24.8245H250.285V32.1023H235.947V43.0688H249.017L248.627 50.6457H235.947V56.8269H250.285V63.9053H227.754Z"
        fill="#0E0E0E"
      />
      <path
        d="M270.185 32.6008V63.9053H261.796V32.6008H254.773V24.8245H277.988L277.5 32.6008H270.185Z"
        fill="#0E0E0E"
      />
      <path
        d="M308.91 63.9054H300.619V50.8453H290.572V63.9054H282.379V24.8246H290.572V43.4677H300.619V24.8246H308.91V63.9054Z"
        fill="#0E0E0E"
      />
      <path
        d="M313.297 63.9054V24.8246H335.926V32.1024H321.588V43.069H334.658L334.268 50.6459H321.588V56.827H335.926V63.9054H313.297Z"
        fill="#0E0E0E"
      />
      <path
        d="M355.435 24.8246C360.897 24.8246 367.335 27.7158 367.335 37.2866C367.335 44.5644 363.628 48.5522 359.434 50.0477L367.042 63.8057H358.849L351.631 50.6459H348.607V63.8057H340.414V24.8246H355.435ZM348.607 43.4677H353.777C356.898 43.4677 359.337 40.8757 359.337 37.7851C359.337 34.5948 356.801 32.1024 353.777 32.1024H348.607V43.4677Z"
        fill="#0E0E0E"
      />
      <path
        d="M316.615 121.828C315.445 125.019 313.787 128.408 309.69 128.408C305.886 128.408 303.838 126.016 300.034 121.031C300.034 121.33 280.038 94.8108 280.038 94.8108C284.232 91.7202 288.622 89.0284 288.622 82.8472C288.622 76.2673 283.55 71.6813 274.576 71.6813C265.602 71.6813 256.629 77.0649 256.629 86.7354C256.629 93.5147 260.335 98.2004 263.457 102.288L282.964 128.01C287.549 133.692 292.231 138.976 300.229 138.976C309.397 138.976 316.42 130.003 318.566 122.427L316.615 121.828ZM271.26 80.3548C271.26 77.2642 273.406 74.7718 278.38 74.7718C283.159 74.7718 286.866 78.2612 286.866 82.8472C286.866 88.5299 282.964 90.8229 278.965 93.2156C274.088 86.7354 271.26 82.6478 271.26 80.3548Z"
        fill="#0E0E0E"
      />
      <path
        d="M302.373 108.469C302.958 108.469 304.031 108.768 304.324 110.064C305.104 113.255 298.374 116.246 298.374 116.246L299.544 117.741C306.86 114.152 312.127 108.868 312.224 101.889C312.224 95.8078 308.323 91.0224 301.983 91.3215C297.106 91.5209 293.399 95.5088 293.692 100.294C293.887 104.78 297.594 108.469 302.373 108.469Z"
        fill="#0E0E0E"
      />
      <path
        d="M259.849 116.146C258.288 116.245 257.117 115.946 256.532 114.152C255.167 109.765 263.36 104.78 263.36 104.78L262.092 103.185C252.533 108.768 245.998 116.644 246.583 126.115C247.168 134.39 252.923 139.574 259.751 139.674C267.457 139.774 273.016 133.493 272.138 126.514C271.456 120.333 265.994 115.847 259.849 116.146Z"
        fill="#0E0E0E"
      />
      <path
        d="M71.4015 73.8748H57.7461V139.076H71.4015V73.8748Z"
        fill="#0E0E0E"
      />
      <path
        d="M104.664 86.8352V139.076H90.6186V86.8352H78.8164V73.8748H117.637L116.954 86.8352H104.664Z"
        fill="#0E0E0E"
      />
      <path
        d="M134.606 131.499L131.583 139.076H115.781L145.336 71.4819L174.89 139.076H159.186L156.26 131.499H134.606ZM145.433 104.282L139.386 119.336H151.383L145.433 104.282Z"
        fill="#0E0E0E"
      />
      <path
        d="M218.394 139.076H182.402V73.8748H195.96V127.312H219.077L218.394 139.076Z"
        fill="#0E0E0E"
      />
      <path
        d="M240.241 73.8748H226.586V139.076H240.241V73.8748Z"
        fill="#0E0E0E"
      />
      <path
        d="M367.141 141.169L335.441 108.968V139.076H321.785V71.5815L353.388 105.578V73.7749H367.141V141.169Z"
        fill="#0E0E0E"
      />
    </svg>
  )
}
