/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import { prismicRepo } from '../../prismic-configuration'
import { linkResolver } from './linkResolver'

import IndexPage from '../pages/index'
import Contact from '../pages/contact'
import AboutPage from '../pages/about'
import Journal from '../pages/journal'
import Products from '../pages/products'
import Recipes from '../pages/recipes'
import WhereToBuyPage from '../pages/where-to-buy'
import JournalPageTemplate from '../templates/journal-page-template'
import LegalPageTemplate from '../templates/legal-page-template'
import ProductPageTemplate from '../templates/product-page-template'
import ProductTypePageTemplate from '../templates/product-type-page-template'
import RecipePageTemplate from '../templates/recipe-page-template'

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: prismicRepo,
    linkResolver,
    componentResolver: {
      homepage: IndexPage,
      about_page: AboutPage,
      contact_page: Contact,
      journal_index_page: Journal,
      product_type_index_page: Products,
      recipes_index_page: Recipes,
      where_to_buy_page: WhereToBuyPage,
      journal_page: JournalPageTemplate,
      legal_page: LegalPageTemplate,
      product_page: ProductPageTemplate,
      product_type_page: ProductTypePageTemplate,
      recipe_page: RecipePageTemplate,
    },
  },
]
