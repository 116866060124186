import React from 'react'

const MadeInItalyGraphic = () => (
  <svg
    width="931"
    height="128"
    viewBox="0 0 931 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M374.362 0H344V127.688H374.362V0Z" fill="#0E0E0E" />
    <path d="M374.363 0H344V127.688H374.363V0Z" fill="#0E0E0E" />
    <path d="M361.885 12.5715H356.582V115.012H361.885V12.5715Z" fill="white" />
    <path
      d="M633.908 127.688H667.494L619.246 0H606.664H594.083L545.73 127.688H579.317L585.14 109.298H627.981L633.908 127.688ZM593.667 82.8047L606.664 41.8699L619.662 82.8047H593.667Z"
      fill="#0E0E0E"
    />
    <path
      d="M643.686 115.116H649.301L613.011 12.6753H600.429L564.139 115.116H569.754L575.577 98.5969H637.863L643.686 115.116ZM577.345 93.4021L604.068 17.974H609.163L635.887 93.4021H577.345Z"
      fill="white"
    />
    <path
      d="M718.124 0V127.688H784.465V97.35H748.487V0H718.124Z"
      fill="#0E0E0E"
    />
    <path
      d="M730.706 12.5715V115.012V115.116H771.883V109.922H735.905V12.5715H730.706Z"
      fill="white"
    />
    <path
      d="M501.734 0H425.723V30.3375H448.496V127.688H478.858V30.3375H501.734V0Z"
      fill="#0E0E0E"
    />
    <path
      d="M489.153 12.5715H438.305V17.7663H461.078V115.012H466.381V17.7663H489.153V12.5715Z"
      fill="white"
    />
    <path
      d="M930.352 0H896.558L878.153 44.3634L859.748 0H826.058L862.868 79.6878V127.688H893.231V80.4151L930.352 0Z"
      fill="#0E0E0E"
    />
    <path
      d="M878.157 72.727L851.33 12.5715H845.611L875.35 79.3763V115.116H880.653V80.1036L910.704 12.5715H904.985L878.157 72.727Z"
      fill="white"
    />
    <path
      d="M257.171 45V64.4309L244.014 45H236.483V82.1393H245.315V62.648L258.532 82.1393H266.002V45H257.171Z"
      fill="#0E0E0E"
    />
    <path d="M218.966 45H210.135V82.1393H218.966V45Z" fill="#0E0E0E" />
    <path d="M218.964 45H210.133V82.1393H218.964V45Z" fill="#0E0E0E" />
    <path
      d="M77.5179 82.1393H87.2869L73.2534 45H69.5939H65.9343L51.8706 82.1393H61.6396L63.3333 76.7905H75.794L77.5179 82.1393ZM65.8133 69.0846L69.5939 57.1783L73.3744 69.0846H65.8133Z"
      fill="#0E0E0E"
    />
    <path
      d="M158.241 59.2634V53.824H168.736V53.6125V45H149.41V82.1393H168.736V73.3153H158.241V67.5435H168.736V59.2634H158.241Z"
      fill="#0E0E0E"
    />
    <path
      d="M28.581 45L18.056 60.5024L7.53087 45H0V82.1393H8.83139V62.648L18.056 76.2466L27.2805 62.648V82.1393H36.1119V45H28.581Z"
      fill="#0E0E0E"
    />
    <path
      d="M101.386 82.1394V45.0303H115.117C118.717 45.0303 121.892 45.816 124.584 47.3269C126.913 48.6566 128.848 50.5302 130.33 52.857C133.143 57.3597 133.355 62.0739 133.355 63.4337C133.355 66.3045 132.901 70.505 130.724 74.2824C128.667 77.8482 124.463 82.1091 116.055 82.1091H101.386V82.1394ZM116.055 73.3154C120.592 73.3154 122.225 71.3813 123.072 69.9308C124.342 67.7248 124.524 64.9145 124.524 63.4639C124.524 62.2854 124.161 59.6865 122.83 57.5712C121.257 55.063 118.717 53.8543 115.087 53.8543H110.157V73.3154H116.055Z"
      fill="#0E0E0E"
    />
  </svg>
)

export default MadeInItalyGraphic
