import React from 'react'
import { translations } from 'crostamollica-components/utils'

const messages = translations()

export const RequiredMessage = () => {
  return (
    <span className="contact-form__required">
      {messages.contact_form.errors.required}
    </span>
  )
}
