import React from 'react'
import ProductSummary from '../products/product-summary'

const RecipeSidebar = ({ recipe: { ingredients, featured_product } }) => (
  <aside className="recipe-sidebar">
    {ingredients.length > 0 && (
      <div className="recipe-sidebar__ingredients">
        <h3 className="recipe-sidebar__ingredients-title">Ingredients</h3>

        <ul className="recipe-sidebar__ingredients-list">
          {ingredients.map(({ ingredient }) => (
            <li className="recipe-sidebar__ingredients-item" key={ingredient}>
              {ingredient}
            </li>
          ))}
        </ul>
      </div>
    )}

    {featured_product && (
      <div className="recipe-sidebar__featured-product">
        <h3 className="recipe-sidebar__featured-product-title">
          Featured Product
        </h3>

        <ProductSummary product={featured_product.document} />
      </div>
    )}
  </aside>
)

export default RecipeSidebar
