import React, { Component } from 'react'
import { Link } from 'gatsby'

import MenuToggle from './nav/menu-toggle'
import LegalPageNavigation from './legal-page-navigation'
import { bind, debounce } from 'decko'
import HeaderNavigationLinks from './header-navigation-links'
import OurProductsNavigationTitle from './products/our-products-navigation-title'
import { Social } from 'crostamollica-components/components'
import { NavOpenContext } from './context/nav-open-context'

export default class HeaderNavigation extends Component {
  constructor(props) {
    super(props)

    /**
     * @type {string}
     */
    this.id = 'main-nav'

    /**
     * @type {object}
     */
    this.state = {
      mobile: false,
    }
  }

  componentDidMount() {
    this.setMobileState()

    window.addEventListener('resize', this.setMobileState, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobileState)
  }

  @bind
  @debounce(100)
  setMobileState() {
    this.setState({
      mobile: !window.matchMedia('(min-width: 63.75em)').matches,
    })
  }

  render() {
    return (
      <NavOpenContext.Consumer>
        {({ navOpen, toggleNav }) => (
          <nav
            className="header__nav nav"
            id={this.id}
            aria-hidden={this.state.mobile ? !navOpen[this.id] : true}
            {...(this.state.mobile && navOpen['product-subnav']
              ? { 'aria-activedescendant': 'product-subnav' }
              : {})}
          >
            <ul className="nav__list">
              <li className="nav__list-item">
                <MenuToggle
                  target="product-subnav"
                  className="nav__link"
                  activeClassName="nav__link--active"
                  label={<OurProductsNavigationTitle />}
                />
              </li>
              <HeaderNavigationLinks />
            </ul>

            {this.state.mobile && (
              <ul className="nav__list nav__list--secondary">
                <LegalPageNavigation />
              </ul>
            )}

            {this.state.mobile && <Social className="nav__social" />}
          </nav>
        )}
      </NavOpenContext.Consumer>
    )
  }
}
