import React from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { Heading } from 'crostamollica-components/components'
import { Image } from '@superrb/gatsby-addons/components'
import WhereToBuyPopup from './where-to-buy-popup'
import { Stripes } from 'crostamollica-components/assets'
import { bind, debounce } from 'decko'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import ProductFlag from '../product-flag'

export default class ProductPageHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: true,
    }
  }

  componentDidMount() {
    this.setMobileState()
    window.addEventListener('resize', this.setMobileState, { passive: true })
  }

  @bind
  @debounce(100)
  setMobileState() {
    this.setState({
      mobile: !window.matchMedia('(min-width: 30em)').matches,
    })
  }

  render() {
    const {
      heading,
      title,
      subHeading,
      content,
      imageOne,
      imageTwo,
      crumbs,
      awards,
      flags
    } = this.props

    const retailers = this.props.retailers

    return (
      <section className="product-page-header">
        <div className="product-page-header__container container container--flex">
          <div className="product-page-header__column product-page-header__column--image">
            <div className="product-page-header__image-container">
              {imageOne && (
                <figure className="product-page-header__image product-page-header__image--primary">
                  <Image
                    image={imageOne}
                    sizes="(min-width: 45em) 50vw, 100vw"
                    layout={ImageLayout.cover}
                  />
                </figure>
              )}

              {imageTwo && (
                <figure className="product-page-header__image product-page-header__image--secondary">
                  <Image
                    image={imageTwo}
                    sizes="(min-width: 45em) 37.5vw, 75vw"
                  />
                </figure>
              )}
            </div>
          </div>
          <div className="product-page-header__column product-page-header__column--content">
            <div className="product-page-header__content">
              {!this.state.mobile && crumbs && (
                <Breadcrumb
                  crumbs={crumbs}
                  crumbLabel={title ? title : heading.text}
                />
              )}

              <div className="product-page-header__awards">
                {awards &&
                  awards?.awards?.document?.data?.award &&
                  awards.map(award => {
                    return (
                      <Image
                        image={award?.awards?.document?.data?.award_image}
                        sizes="(min-width: 45em) 37.5vw, 75vw"
                        className="product-page-header__awards-logos"
                      />
                    )
                  })}
              </div>

              {Heading && (
                <Heading
                  className="product-page-header__heading"
                  text={heading.text}
                  level="1"
                />
              )}

              {subHeading && (
                <div className="product-page-header__sub-heading">
                  {subHeading.text}
                </div>
              )}

              {content && (
                <div className="product-page-header__text rte">
                  <RichText field={content.richText} />
                </div>
              )}

              {retailers.length > 0 && (
                <WhereToBuyPopup retailers={retailers} />
              )}

              {flags.length > 0 && (
                <div className="product__flags product-page-header__flags">
                  {flags.map(flag => (
                    <ProductFlag flag={flag} key={flag.title} />
                  ))}
                </div>
              )}
            </div>

            <Stripes />
          </div>
        </div>
      </section>
    )
  }
}
