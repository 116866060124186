import React from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { Heading } from 'crostamollica-components/components'
import { Image } from '@superrb/gatsby-addons/components'
import { Stripes } from 'crostamollica-components/assets'
import { PrismicLink } from 'crostamollica-components/utils'
import { bind, debounce } from 'decko'
import { ImageLayout } from '@superrb/gatsby-addons/types'

export default class RecipePageHeader extends React.Component {
  constructor(props) {
    super(props)

    /**
     * @type {object}
     */
    this.state = {
      mobile: true,
    }
  }

  componentDidMount() {
    this.setMobileState()

    window.addEventListener('resize', this.setMobileState, { passive: true })
  }

  @bind
  @debounce(100)
  setMobileState() {
    this.setState({
      mobile: !window.matchMedia('(min-width: 30em)').matches,
    })
  }

  render() {
    const {
      location,
      category,
      heading,
      author,
      title,
      text,
      cookTime,
      prepTime,
      serves,
      image,
      crumbs,
    } = this.props

    return (
      <section className="recipe-page-header">
        <div className="recipe-page-header__container container container--flex">
          <div className="recipe-page-header__column recipe-page-header__column--image">
            <div className="recipe-page-header__image-container">
              {image && (
                <figure className="recipe-page-header__image">
                  <Image
                    image={image}
                    sizes="(min-width: 45em) 50vw, 100vw"
                    layout={ImageLayout.cover}
                  />
                </figure>
              )}
            </div>
          </div>

          <div className="recipe-page-header__column recipe-page-header__column--content">
            <div className="recipe-page-header__content">
              {!this.state.mobile && crumbs && (
                <Breadcrumb
                  crumbs={crumbs}
                  crumbLabel={title ? title : heading.text}
                />
              )}

              {heading && (
                <Heading
                  className="recipe-page-header__heading"
                  text={heading.text}
                  level="1"
                />
              )}

              <div className="recipe-page-header__meta">
                {category && (
                  <span className="recipe-page-header__category">
                    {category.data.name}
                  </span>
                )}
                {author && (
                  <span className="recipe-page-header__author">
                    Recipe by {author}
                  </span>
                )}
              </div>

              {text && (
                <div className="recipe-page-header__text rte">
                  <RichText field={text.richText} />
                </div>
              )}

              <dl className="recipe-page-header__recipe-info">
                {cookTime ? (
                  <>
                    <dt className="recipe-page-header__recipe-info-label">
                      Cook time:
                    </dt>
                    <dd className="recipe-page-header__recipe-info-value">
                      {cookTime} mins
                    </dd>
                  </>
                ) : null}
                {prepTime ? (
                  <>
                    <dt className="recipe-page-header__recipe-info-label">
                      Prep time:
                    </dt>
                    <dd className="recipe-page-header__recipe-info-value">
                      {prepTime} mins
                    </dd>
                  </>
                ) : null}
                {serves ? (
                  <>
                    <dt className="recipe-page-header__recipe-info-label">
                      Serves:
                    </dt>
                    <dd className="recipe-page-header__recipe-info-value">
                      {serves}
                    </dd>
                  </>
                ) : null}
              </dl>
            </div>

            <Stripes />
          </div>
        </div>
      </section>
    )
  }
}
