import { LiveNodeList } from 'live-node-list'
import React, { Component } from 'react'
import isInViewport from '../../helpers/is-in-viewport'

export default class ProductSubNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: null,
    }
  }

  componentDidMount() {
    this.sections = new LiveNodeList(
      '#discover, #related-articles, #ingredients, #explore',
    )

    this.sections.addDelegatedEventListener(
      window,
      'scroll',
      _ => {
        this.sections.forEach(item => {
          if (isInViewport(item, { x: 0, y: window.innerHeight / -2 })) {
            this.setState({
              active: item.id,
            })
          }
        })
      },
      { passive: true },
    )
  }

  render() {
    const { showQualityAndPackaging } = this.props

    return (
      <nav className="product-sub-nav">
        <ul className="product-sub-nav__list">
          <li className="product-sub-nav__list-item">
            <a
              href="#discover"
              className={`product-sub-nav__link ${
                this.state.active === 'discover' ? 'active' : ''
              }`}
            >
              Discover
            </a>
          </li>

          {showQualityAndPackaging && (
            <li className="product-sub-nav__list-item">
              <a
                href="#related-articles"
                className={`product-sub-nav__link ${
                  this.state.active === 'related-articles' ? 'active' : ''
                }`}
              >
                Quality & Packaging
              </a>
            </li>
          )}

          <li className="product-sub-nav__list-item">
            <a
              href="#ingredients"
              className={`product-sub-nav__link ${
                this.state.active === 'ingredients' ? 'active' : ''
              }`}
            >
              Ingredients
            </a>
          </li>

          <li className="product-sub-nav__list-item">
            <a
              href="#explore"
              className={`product-sub-nav__link ${
                this.state.active === 'explore' ? 'active' : ''
              }`}
            >
              Explore
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}
