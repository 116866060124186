import React from 'react'
import { Stripes } from 'crostamollica-components/assets'
import ArticleSummary from '../articles/article-summary'

export default class RelatedArticles extends React.Component {
  render() {
    const { articleOne, articleTwo } = this.props

    return (
      <section className="related-articles" id="related-articles">
        <div className="related-articles__container container container--flex">
          <div className="related-articles__stripes">
            <Stripes />
          </div>

          <ul className="related-articles__articles">
            {articleOne && (
              <ArticleSummary
                className="related-articles__article"
                article={articleOne}
              />
            )}

            {articleTwo && (
              <ArticleSummary
                className="related-articles__article"
                article={articleTwo}
              />
            )}
          </ul>
        </div>
      </section>
    )
  }
}
