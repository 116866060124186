import React, { Component } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import 'animate.css'
import 'crostamollica-components/src/components/photo-slider/photo-gallery-animate-override.css'
import CtaLink from '../cta-link'
import { RichText } from '@superrb/gatsby-addons/components'
import { BigArrowLeft } from './big-arrow-left'
import { BigArrowRight } from './big-arrow-right'
import { Link } from 'gatsby'
import { Heading } from 'crostamollica-components/components'

export class FeaturedProductsHome extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
    }

    this.id = `photoslider_id_${props.id}`
    this.querySelectorId = `#${this.id}`
  }

  isMobile() {
    let result = true

    try {
      let mql = window.matchMedia('(min-width: 63.75em)')

      result = !mql.matches
    } catch (e) {
      // ignore if API is not present
    }

    return result
  }

  back = () => {
    this.page(-1)
  }

  next = () => {
    this.page(1)
  }

  page(value) {
    let newIndex = this.state.index + value

    if (newIndex >= this.props.products.length) {
      newIndex = 0
    }

    if (newIndex < 0) {
      newIndex = this.props.products.length - 1
    }

    this.setState({
      index: newIndex,
      isMobile: this.isMobile(),
    })
  }

  padZero(value) {
    let result = value

    try {
      result = value.toString().padStart(2, '0')
    } catch (e) {
      // pass
    }

    return result
  }

  setRootRef = ref => (this.rootRef = ref)

  render() {
    if (this.props.products?.length === undefined) {
      return <div />
    }

    const calculatePageClass = (index, length) =>
      `featured-products-home__page-number ${
        index === this.state.index ? 'active' : ''
      } ${index < length ? 'dashed' : ''}`

    return (
      <section
        className="two-column-block__container container--flex reverse featured-products-home featured-products-home__holding"
        id={this.id}
        ref={this.setRootRef}
        aria-live="assertive"
      >
        <div className="two-column-block__column featured-products-home__left">
          <div className="featured-products-home__info-panel">
            {this.props.products.map((product, index) => {
              if (index === this.state.index) {
                return (
                  <>
                    <div
                      className={`animate__animated animate__fadeInUp animate__faster`}
                    >
                      <h2 className="featured-products-home__heading">
                        Featured Products
                      </h2>
                      <Heading
                        level="3"
                        className="featured-products-home__sub-heading"
                        text={
                          product.featured_product.document.data.product_name
                            .text
                        }
                      />
                    </div>

                    <div
                      className={`animate__animated animate__fadeInUp animate__faster animate__delay-250ms`}
                    >
                      <RichText
                        field={
                          product.featured_product.document.data.product_content
                            .richText
                        }
                      />
                    </div>

                    <div
                      className={`animate__animated animate__fadeInUp animate__faster animate__delay-500ms`}
                    >
                      <CtaLink
                        to={
                          '/products/' +
                          product.featured_product.document.data.product_type
                            .uid +
                          '/' +
                          product.featured_product.uid
                        }
                      >
                        Learn More
                      </CtaLink>
                    </div>
                  </>
                )
              }
            })}
          </div>
        </div>
        <div className="two-column-block__column featured-products-home__right">
          <div className="featured-products-home__fixed">
            {this.props.products.map((product, index) => (
              <Image
                className="featured-products-home__image"
                image={product.featured_product.document.data.product_image_01}
                sizes="(min-width: 45em) 25vw, 100vw"
                aria-current={index === this.state.index}
                layout={ImageLayout.cover}
              />
            ))}
          </div>

          <div className="featured-products-home__imagery-panel">
            <div className="featured-products-home__imagery-holding">
              {this.props.products.map((product, index) => (
                <Link
                  to={
                    '/products/' +
                    product.featured_product.document.data.product_type.uid +
                    '/' +
                    product.featured_product.uid
                  }
                >
                  <Image
                    image={
                      product.featured_product.document.data.product_image_02
                    }
                    aria-current={index === this.state.index}
                  />
                </Link>
              ))}
            </div>

            <div className="featured-products-home__controls">
              <span className="featured-products-home__pages">
                {this.props.products.map((product, index) => (
                  <span
                    className={calculatePageClass(
                      index,
                      this.props.products.length
                    )}
                    key={index}
                  >
                    {this.padZero(index + 1)}
                  </span>
                ))}
              </span>
              <span className="featured-products-home__buttons">
                <button
                  className="featured-products-home__control-button"
                  onClick={this.back}
                >
                  <BigArrowLeft />
                  <span>Previous product</span>
                </button>
                <button
                  className="featured-products-home__control-button"
                  onClick={this.next}
                >
                  <BigArrowRight />
                  <span>Next product</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
