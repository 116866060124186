import React from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { Hreflang, PageHeader } from 'crostamollica-components/components'
import ProductListing from '../components/products/product-listing'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const Products = ({ data, location, pageContext }) => {
  const pageData = data.prismicProductTypeIndexPage.data
  const productPages = data.allPrismicProductPage.nodes
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <PageHeader
        location={location}
        heading={pageData.product_types_title}
        title={pageData.meta_navigation_title}
        text={pageData.product_types_description}
        image={pageData.product_types_image}
        crumbs={crumbs}
      />

      <ProductListing products={productPages} />
    </>
  )
}
export const query = graphql`
  {
    prismicProductTypeIndexPage {
      _previewable
      data {
        product_types_title {
          text
          richText
        }
        product_types_description {
          richText
        }
        product_types_image {
          ...Image
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
      }
    }
    allPrismicProductPage {
      nodes {
        uid
        data {
          meta_navigation_title
          product_type {
            uid
          }
          product_name {
            richText
            text
          }
          product_sub_heading {
            text
            richText
          }
          product_image_01 {
            ...Image
          }
          product_image_02 {
            ...Image
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Products)
