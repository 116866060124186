import React, { Component } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import 'animate.css'
import 'crostamollica-components/src/components/photo-slider/photo-gallery-animate-override.css'
import { RichText } from '@superrb/gatsby-addons/components'
import { BigArrowLeft } from '../featured-products/big-arrow-left'
import { BigArrowRight } from '../featured-products/big-arrow-right'
import { Heading } from 'crostamollica-components/components'
import { Stripes } from 'crostamollica-components/assets'
import { ImageLayout } from '@superrb/gatsby-addons/types'

export class InformationSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      information: props.information,
      index: 0,
    }

    this.id = `information_slider_id_${props.id}`
    this.querySelectorId = `#${this.id}`
  }

  isMobile() {
    let result = true

    try {
      let mql = window.matchMedia('(min-width: 63.75em)')

      result = !mql.matches
    } catch (e) {
      // ignore if API is not present
    }

    return result
  }

  back = () => {
    this.page(-1)
  }

  next = () => {
    this.page(1)
  }

  page(value) {
    let newIndex = this.state.index + value

    if (newIndex >= this.state.information.length) {
      newIndex = 0
    }

    if (newIndex < 0) {
      newIndex = this.state.information.length - 1
    }

    this.setState({
      index: newIndex,
      isMobile: this.isMobile(),
    })
  }

  padZero(value) {
    let result = value

    try {
      result = value.toString().padStart(2, '0')
    } catch (e) {
      // pass
    }

    return result
  }

  setRootRef = ref => (this.rootRef = ref)

  render() {
    if (this.state.information.length === undefined) {
      return <div />
    }

    const information = this.state.information[this.state.index]

    const calculatePageClass = (index, length) =>
      `information-slider__page-number ${
        index === this.state.index ? 'active' : ''
      } ${index < length - 1 ? 'dashed' : 'dashed'}`

    return (
      <section
        className="two-column-block__container container--flex information-slider"
        id={this.id}
        aria-live="assertive"
      >
        <div className="two-column-block__column information-slider__left information-slider__holding">
          {this.props.information.map((information, index) => (
            <Image
              image={information.image}
              sizes="(min-width: 45em) 50vw, 100vw"
              className="information-slider__fixed"
              layout={ImageLayout.cover}
              aria-current={index === this.state.index}
            />
          ))}
        </div>
        <div
          className="two-column-block__column information-slider__right"
          ref={this.setRootRef}
        >
          <div className="information-slider__stripe-container">
            <Stripes />
          </div>

          <div className="information-slider__info-panel">
            {this.props.information.map((information, index) => {
              if (index === this.state.index) {
                return (
                  <>
                    <div
                      className={`animate__animated animate__fadeInUp animate__faster`}
                    >
                      {information.heading && (
                        <Heading
                          text={information.heading.text}
                          level="2"
                          className="information-slider__heading"
                        />
                      )}
                      {information.sub_heading && (
                        <Heading
                          text={information.sub_heading.text}
                          level="3"
                          className="information-slider__sub-heading"
                        />
                      )}
                    </div>
                    <div
                      className={`animate__animated animate__fadeInUp animate__faster animate__delay-250ms`}
                    >
                      {information.description && (
                        <RichText field={information.description.richText} />
                      )}
                    </div>
                  </>
                )
              }
            })}
          </div>

          <div className="information-slider__controls">
            <div className="information-slider__pages">
              {this.state.information.map((product, index) => (
                <span
                  className={calculatePageClass(
                    index,
                    this.state.information.length
                  )}
                  key={index}
                >
                  {this.padZero(index + 1)}
                </span>
              ))}
            </div>
            <button
              className="information-slider__control-button button-left"
              onClick={this.back}
            >
              <BigArrowLeft />
              <span>Previous page</span>
            </button>
            <button
              className="information-slider__control-button button-right"
              onClick={this.next}
            >
              <BigArrowRight />
              <span>Next page</span>
            </button>
          </div>
        </div>
      </section>
    )
  }
}
