import React, { Component } from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { Image } from '@superrb/gatsby-addons/components'
import { PrismicLink } from 'crostamollica-components/utils'
import { ImageLayout } from '@superrb/gatsby-addons/types'

export default class Retailer extends Component {
  render() {
    const uid = this.props.retailer.uid
    const data = this.props.retailer.data
    const type = this.props.type ? this.props.type : 'in-store'

    if (type === 'online') {
      return (
        <div key={uid} className="where-to-buy-stores-online__retailer">
          <div className="where-to-buy-stores-online__inner">
            {data.website_address && (
              <div className="where-to-buy-stores-online__link">
                <a
                  href={data.website_address.url}
                  target={data.website_address.target}
                >
                  See website
                </a>
              </div>
            )}
            <div className="where-to-buy-stores-online__logo">
              {data.logo && (
                <Image image={data.logo} layout={ImageLayout.contain} />
              )}
            </div>
            <div className="where-to-buy-stores-online__name">
              <RichText field={data.name.richText} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="where-to-buy-stores-retail__retailer">
          <div className="where-to-buy-stores-online__inner">
            <div className="where-to-buy-stores-retail__logo">
              <div>
                {data.logo && (
                  <Image image={data.logo} layout={ImageLayout.contain} />
                )}
              </div>
            </div>
            <div className="where-to-buy-stores-retail__name">
              <RichText field={data.name.richText} />
            </div>
          </div>
        </div>
      )
    }
  }
}
