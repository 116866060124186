import React from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { Heading } from 'crostamollica-components/components'
import { PrismicLink } from 'crostamollica-components/utils'

export default class ProductIngredients extends React.Component {
  render() {
    const { ingredients, nutritionalInformation, portionInformation } =
      this.props
    const tableHeading = nutritionalInformation[0]
    const tableRows = nutritionalInformation.slice(1)

    return (
      <section className="product-ingredients" id="ingredients">
        <div className="product-ingredients__container container container--flex">
          <div className="product-ingredients__column product-ingredients__column--ingredients">
            <Heading
              className="product-ingredients__heading"
              text="Ingredients"
              level="4"
            />

            <div className="rte">
              {ingredients && <RichText field={ingredients.richText} />}
            </div>

            <Heading
              className="product-ingredients__sub-text"
              text="Made in Italy"
              level="5"
            />
          </div>

          <div className="product-ingredients__column product-ingredients__column--nutritional">
            <Heading
              className="product-ingredients__heading"
              text="Nutritional Information"
              level="4"
            />

            {nutritionalInformation && nutritionalInformation.length ? (
              <table className="product-ingredients__table responsive-table">
                <thead>
                  <tr>
                    <th>{tableHeading.column_01.text}</th>

                    <th>{tableHeading.column_02.text}</th>

                    <th>{tableHeading.column_03.text}</th>
                  </tr>
                </thead>

                <tbody>
                  {tableRows.map((row, index) => (
                    <tr key={index} className="responsive-table-row">
                      <td data-label={tableHeading.column_01.text}>
                        {row.column_01.text}
                        {row.column_01.text.length ? (
                          <span className="colon">:</span>
                        ) : (
                          ''
                        )}
                      </td>

                      <td data-label={tableHeading.column_02.text}>
                        {row.column_02.text}
                      </td>

                      <td data-label={tableHeading.column_03.text}>
                        {row.column_03.text}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              ''
            )}

            {portionInformation && (
              <div className="product-ingredients__portion-information rte">
                <RichText field={portionInformation.richText} />
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}
