import React from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { Hreflang, PageHeader } from 'crostamollica-components/components'
import JournalListing from '../components/journal/article-listing'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const Journal = ({ data, location, pageContext }) => {
  const pageData = data.page.data
  const articles = data.articles.nodes
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <PageHeader
        location={location}
        heading={pageData.title}
        title={pageData.meta_navigation_title}
        text={pageData.description}
        image={pageData.image}
        crumbs={crumbs}
      />

      <JournalListing articles={articles} />
    </>
  )
}
export const query = graphql`{
  page: prismicJournalIndexPage {
    _previewable
    data {
      title {
        text
        richText
      }
      description {
        richText
      }
      image {
        ...Image
      }
      meta_description
      meta_robots
      meta_title
      meta_navigation_title
      meta_image {
        ...Image
      }
    }
  }
  articles: allPrismicJournalPage(sort: {first_publication_date: DESC}) {
    nodes {
      uid
      data {
        title {
          richText
          text
        }
        main_image {
          ...Image
        }
        category {
          document {
            ... on PrismicJournalCategory {
              uid
              data {
                name
              }
            }
          }
        }
      }
    }
  }
}`

export default withPrismicPreview(Journal)
