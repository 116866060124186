/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = doc => {
  const productTypesMap = require('../data/product-types-map.json')

  switch (doc.type) {
    // dynamic product type pages
    case 'product_type_page': {
      return `/products/${doc.uid}`
    }

    // dynamic product detail pages
    case 'product_page': {
      if (productTypesMap.hasOwnProperty(doc.uid)) {
        return `/products/${productTypesMap[doc.uid]}/${doc.uid}`
      }
      return `/404`
    }

    // dynamic recipe pages
    case 'recipe_page': {
      return `/recipes/${doc.uid}`
    }

    // dynamic journal pages
    case 'journal_page': {
      return `/journal/${doc.uid}`
    }

    // dynamic legal pages
    case 'legal_page': {
      return `/legal/${doc.uid}`
    }

    // static pages
    case 'about_page': {
      return `/about`
    }
    case 'contact_page': {
      return `/contact`
    }
    case 'product_type_index_page': {
      return `/products`
    }
    case 'where_to_buy_page': {
      return `/where-to-buy`
    }
    case 'homepage': {
      return `/`
    }

    default:
      return '/'
  }
}
