import React, { useRef, MutableRefObject } from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { Heading } from 'crostamollica-components/components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { Stripes } from 'crostamollica-components/assets'
import { Image } from '@superrb/gatsby-addons/components'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { useParallax } from 'crostamollica-components/hooks'

const AboutPageHeader = ({
  location,
  heading,
  title,
  content,
  imageOne,
  imageTwo,
  crumbs,
  className,
  ...props
}) => {
  const containerRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>
  const pos = useParallax(containerRef)

  return (
    <section
      className={`${className} about-page-header two-image-text-block two-image-text-block--about-page-header two-image-text-block--right`}
      {...props}
      ref={containerRef}
    >
      <div className="two-image-text-block__container container container--flex">
        <div className="two-image-text-block__column two-image-text-block__column--image">
          {imageOne && (
            <Image
              image={imageOne}
              sizes="(min-width: 45em) 25vw, 50vw"
              layout={ImageLayout.cover}
              className="two-image-text-block__image"
              style={{ transform: `translateY(${pos / -5}%)` }}
            />
          )}

          {imageTwo && (
            <Image
              image={imageTwo}
              sizes="(min-width: 45em) 25vw, 50vw"
              className="two-image-text-block__image"
              style={{ transform: `translateY(${pos / -2.5}%)` }}
            />
          )}

          <Stripes hideLeftTwo={true} />
        </div>

        <div className="two-image-text-block__column two-image-text-block__column--content">
          {crumbs && (
            <Breadcrumb
              crumbs={crumbs}
              crumbLabel={title ? title : heading.text}
            />
          )}

          {heading && (
            <Heading
              className="two-image-text-block__heading"
              text={heading.text}
              level="1"
            />
          )}

          {content && (
            <div className="two-image-text-block__content">
              <RichText field={content.richText} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default AboutPageHeader
