import { graphql } from 'gatsby'
import { RichText } from '@superrb/gatsby-addons/components'
import React from 'react'
import { Helmet } from 'react-helmet'
import JournalPageHeader from '../components/journal/journal-page-header'
import Seo from '@superrb/gatsby-addons/src/components/seo'
import getContrastingTextColor from '../helpers/get-contrasting-text-color'
import RelatedArticles from '../components/journal/related-articles'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Hreflang } from 'crostamollica-components/components'

const JournalPageTemplate = ({ data, location, pageContext }) => {
  const pageData = data.prismicJournalPage.data
  const relatedArticles = data.allPrismicJournalPage?.nodes
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <>
      {pageData.highlight_color && (
        <Helmet>
          <style>{`
          :root {
            --page-highlight-color: ${pageData.highlight_color};
            --page-highlight-text-color: ${getContrastingTextColor(
              pageData.highlight_color
            )};
          }
        `}</style>
        </Helmet>
      )}

      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <JournalPageHeader
        location={location}
        category={pageData.category?.document}
        heading={pageData.title}
        author={pageData.author}
        title={pageData.meta_navigation_title}
        text={pageData.description}
        cookTime={pageData.cook_time}
        prepTime={pageData.prep_time}
        serves={pageData.serves}
        image={pageData.main_image}
        crumbs={crumbs}
        date={data.article?.first_publication_date}
      />

      <section className="journal-detail">
        <div className="journal__container container container--flex">
          {pageData.content && (
            <div className="journal-detail__content rte">
              <RichText field={pageData.content.richText} />
            </div>
          )}
        </div>
      </section>
      <RelatedArticles articles={relatedArticles} />
    </>
  )
}

export default withPrismicPreview(JournalPageTemplate)

export const query = graphql`query GetArticle($slug: String!) {
  prismicJournalPage(uid: {eq: $slug}) {
    _previewable
    uid
    data {
      title {
        text
        richText
      }
      description {
        text
        richText
      }
      category {
        document {
          ... on PrismicJournalCategory {
            uid
            data {
              name
            }
          }
        }
      }
      main_image {
        ...Image
      }
      content {
        richText
        text
      }
      author
      meta_description
      meta_robots
      meta_title
      meta_navigation_title
      meta_image {
        ...Image
      }
    }
    first_publication_date(formatString: "YYYY-MM-DD")
  }
  allPrismicJournalPage(
    limit: 3
    filter: {uid: {ne: $slug}}
    sort: {first_publication_date: DESC}
  ) {
    nodes {
      uid
      data {
        title {
          richText
          text
        }
        main_image {
          ...Image
        }
        category {
          document {
            ... on PrismicJournalCategory {
              uid
              data {
                name
              }
            }
          }
        }
      }
    }
  }
}`
