import React from 'react'
import PropTypes from 'prop-types'
import {
  InstagramFeed,
  Newsletter,
  RegionSelector,
  Social,
} from 'crostamollica-components/components'
import { LogoImage } from 'crostamollica-components/assets'
import FooterNavigation from './footer-navigation'

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <div className="footer__main">
      <div className="footer__container footer__container--instagram">
        <InstagramFeed />
      </div>

      <div className="footer__container footer__container--footer-nav container container--flex">
        <div className="footer__column">
          <FooterNavigation />
        </div>

        <div className="footer__column">
          <h4>Follow us</h4>
          <Social className="footer__social" withLabels={true} />
        </div>
      </div>

      <div className="footer__container footer__container--credentials container container--flex">
        <RegionSelector />
        <span className="footer__tagline">Made in Italy</span>

        <span className="footer__copyright">
          &copy;{new Date().getFullYear()} {siteTitle}.
        </span>

        <span className="footer__attribution">
          Website by{' '}
          <a
            href="https://www.superrb.com"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Website by Superrb"
          >
            Superrb
          </a>
          .
        </span>
      </div>
    </div>

    <div className="footer__logo footer-logo">
      <LogoImage />
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
