import { Link } from 'gatsby'
import React from 'react'
import RecipeSummary from './recipe-summary'

const RelatedRecipes = ({ recipes }) => {
  if (!recipes) return null

    return (
      <section className="related-recipes">
        <div className="related-recipes__container container">
          <div className="related-recipes__header">
            <h2 className="related-recipes__title">Other recipes you might like</h2>

            <Link to="/recipes" className="related-recipes__all">
              View all recipes
            </Link>
          </div>

          <ul className="related-recipes__recipes">
            {recipes.map(recipe => (
              <RecipeSummary recipe={recipe} className="related-recipes__recipe"/>
            ))}
          </ul>
        </div>
      </section>
    )
}

export default RelatedRecipes
