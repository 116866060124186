import React from 'react'
import { Link } from 'gatsby'
import { Heading } from 'crostamollica-components/components'
import { Image } from '@superrb/gatsby-addons/components'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import ProductFlag from '../product-flag'

const ProductSummary = ({ product, className = '' }) => {
  if (!product) return null

  const {
    meta_navigation_title,
    product_name,
    product_sub_heading,
    product_image_01,
    product_image_02,
  } = product.data
  const url = '/products/' + product.data.product_type.uid + '/' + product.uid

  const flags =
    product.data.flags
      ?.map(flag => flag?.flag?.document?.data)
      .filter(flag => !!flag) || []

  return (
    <li className={`${className} product`}>
      <Link to={url} className="product__link" activeClassName="active">
        <div className="product__image-container">
          {product_image_01 && (
            <Image
              image={product_image_01}
              sizes="(min-width: 45em) 50vw, 100vw"
              className="product__image product__image--primary"
              layout={ImageLayout.cover}
            />
          )}

          {product_image_02 && (
            <Image
              image={product_image_02}
              sizes="(min-width: 45em) 25vw, 50vw"
              className="product__image product__image--secondary"
              layout={ImageLayout.contain}
            />
          )}
        </div>

        {product_name && (
          <Heading
            className="product__heading"
            text={
              meta_navigation_title ? meta_navigation_title : product_name.text
            }
            level="4"
          />
        )}

        {product_sub_heading && (
          <div className="product__sub-heading">{product_sub_heading.text}</div>
        )}

        {flags.length > 0 && (
          <div className="product__flags">
            {flags.map(flag => (
              <ProductFlag key={flag.uid} flag={flag} />
            ))}
          </div>
        )}
      </Link>
    </li>
  )
}

export default ProductSummary
