import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { Link } from 'gatsby'
import { translations } from 'crostamollica-components/utils'

const messages = translations()

export const CookieBanner = () => {
  // Have we already accepted cookies?
  const accepted = Cookies.get('gatsby-accepted-cookies') || false

  const [animate, setAnimate] = useState(false)
  const [showCookieBanner, setShowCookieBanner] = useState(!accepted)

  const close = () => {
    setAnimate(true)

    window.setTimeout(() => {
      setShowCookieBanner(false)
    }, 500)
  }

  const accept = () => {
    Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-google-tagmanager', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-hotjar', 'true', { expires: 30 })
    Cookies.set('gatsby-accepted-cookies', 'true', { expires: 30 })

    close()
  }

  const reject = () => {
    Cookies.remove('gatsby-gdpr-google-analytics', {'path': '/'})
    Cookies.remove('gatsby-gdpr-google-tagmanager', {'path': '/'})
    Cookies.remove('gatsby-gdpr-hotjar', {'path': '/'})
    Cookies.remove('gatsby-accepted-cookies', {'path': '/'})
    close()
  }

  return (
    <React.Fragment>
      {showCookieBanner && (
        <div
          className={`cookie-banner animate__animated ${
            animate ? ' animate__fadeOutDown' : ''
          } `}
        >
          <div className="cookie-banner__container">
            <div className="cookie-banner__message">
              <div
                dangerouslySetInnerHTML={{
                  __html: messages.cookie_banner.text,
                }}
              />
            </div>
            <div className="cookie-banner__control">
              <button onClick={accept} className="cookie-banner__agree" dangerouslySetInnerHTML={{
                __html: messages.cookie_banner.agree,
              }}/>
              <button onClick={reject} className="cookie-banner__reject" dangerouslySetInnerHTML={{
                __html: messages.cookie_banner.reject,
              }}/>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
