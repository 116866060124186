import * as React from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { PageHeader } from 'crostamollica-components/components'
import Faqs from '../components/faqs/faqs'
import ContactForm from '../components/contact/contact-form'
import { FaqsIntro } from 'crostamollica-components/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { useAlternates } from 'crostamollica-components/hooks'
import { Hreflang } from 'crostamollica-components/components'

const ContactPage = ({ data, location, pageContext }) => {
  const pageData = data.prismicContactPage.data
  const crumbs = pageContext?.breadcrumb?.crumbs

  useAlternates(data?.prismicContactPage?.data?.languages)

  return (
    <>
      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <PageHeader
        location={location}
        heading={pageData.main_title}
        title={pageData.meta_navigation_title}
        image={pageData.main_image}
        text={pageData.main_description}
        crumbs={crumbs}
      />

      <div className="contact-faq">
        <FaqsIntro
          heading={pageData.faqs_intro_heading}
          text={pageData.faqs_intro_text}
        />
        <Faqs />
      </div>
      <ContactForm />
    </>
  )
}

export const query = graphql`
  {
    prismicContactPage {
      _previewable
      data {
        main_image {
          ...Image
        }
        main_title {
          text
          richText
        }
        main_description {
          text
          richText
        }
        faqs_intro_heading {
          richText
          text
        }
        faqs_intro_text {
          richText
          text
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        languages {
          locale
          link {
            url
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ContactPage)
