import React from 'react'
import ProductSummary from '../products/product-summary'
import { Heading } from 'crostamollica-components/components'
import { Link } from 'gatsby'

export default class FeaturedProducts extends React.Component {
  render() {
    const { heading, productOne, productTwo, className, ...props } = this.props

    return (
      <section className={`${className} featured-products`} {...props}>
        <div className="featured-products__container container container--flex">
          {heading && (
            <Heading
              className="featured-products__heading"
              text={heading}
              level="4"
            />
          )}

          <Link to="/products" className="featured-products__link">
            Our Products
          </Link>
        </div>

        <div className="featured-products__container container">
          <ul className="featured-products__products">
            {productOne && (
              <ProductSummary
                className="featured-products__product"
                product={productOne}
              />
            )}

            {productTwo && (
              <ProductSummary
                className="featured-products__product"
                product={productTwo}
              />
            )}
          </ul>
        </div>
      </section>
    )
  }
}
