import React from 'react'
import { Link } from 'gatsby'
import { linkResolver } from './linkResolver'

const GatsbyLink = ({ type, element, content, className, children, index }) => {
  if (element.link_type === 'Document') {
    return (
      <>
        {element.rich && (
          <Link
            to={linkResolver(element)}
            key={element.id}
            target={element.target ? element.target : ''}
            className={className}
          >
            {content}
          </Link>
        )}
      </>
    )
  }

  if (element.link_type === 'Web') {
    return (
      <a
        key={element.id}
        href={element.url}
        target={element.target ? element.target : ''}
        className={className}
      >
        {content}
      </a>
    )
  }

  if (element.link_type === 'Media') {
    return (
      <a
        key={element.id}
        href={element.url}
        target="_blank"
        className={className}
        rel="noreferrer"
      >
        {content}
      </a>
    )
  }

  return null
}

export default GatsbyLink
