import React from 'react'
import { AltogetherItalianImage } from './altogether-italian-image'

export const AltogetherItalian = () => {
  return (
    <section className="altogether-italian">
      <div className="altogether-italian__container container">
        <figure className="altogether-italian__logo">
          <div className="altogether-italian__stripe"></div>
          <AltogetherItalianImage />
          <figcaption>
            <span>Altogether Italian</span>
          </figcaption>
        </figure>
      </div>
    </section>
  )
}
