import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import LegalPageNavigation from './legal-page-navigation'

const FooterNavigation = () => {
  const data = useStaticQuery(graphql`
    query FooterNavigationQuery {
      allPrismicProductTypeIndexPage {
        nodes {
          data {
            meta_navigation_title
          }
        }
      }
      allPrismicAboutPage {
        nodes {
          data {
            meta_navigation_title
          }
        }
      }
      allPrismicWhereToBuyPage {
        nodes {
          data {
            meta_navigation_title
          }
        }
      }
      allPrismicContactPage {
        nodes {
          data {
            meta_navigation_title
          }
        }
      }
      allPrismicRecipesIndexPage {
        nodes {
          data {
            meta_navigation_title
          }
        }
      }
      allPrismicJournalIndexPage {
        nodes {
          data {
            meta_navigation_title
          }
        }
      }
    }
  `)

  return (
    <nav className="footer__nav nav">
      <ul className="nav__list nav__list--primary">
        {data.allPrismicProductTypeIndexPage.nodes.map((page, index) => {
          return (
            <li className="nav__list-item" key={index}>
              <Link
                to="/products"
                className="nav__link"
                activeClassName="nav__link--active"
              >
                {page.data.meta_navigation_title}
              </Link>
            </li>
          )
        })}
        {data.allPrismicAboutPage.nodes.map((page, index) => {
          return (
            <li className="nav__list-item" key={index}>
              <Link
                to="/about"
                className="nav__link"
                activeClassName="nav__link--active"
              >
                {page.data.meta_navigation_title}
              </Link>
            </li>
          )
        })}
        {data.allPrismicWhereToBuyPage.nodes.map((page, index) => {
          return (
            <li className="nav__list-item" key={index}>
              <Link
                to="/where-to-buy"
                className="nav__link"
                activeClassName="nav__link--active"
              >
                {page.data.meta_navigation_title}
              </Link>
            </li>
          )
        })}
        {data.allPrismicRecipesIndexPage.nodes.map((page, index) => {
          return (
            <li className="nav__list-item" key={index}>
              <Link
                to="/recipes"
                className="nav__link"
                activeClassName="nav__link--active"
              >
                {page.data.meta_navigation_title}
              </Link>
            </li>
          )
        })}
        {/*{data.allPrismicJournalIndexPage.nodes.map((page, index) => {*/}
        {/*  return (*/}
        {/*    <li className="nav__list-item" key={index}>*/}
        {/*      <Link*/}
        {/*        to="/journal"*/}
        {/*        className="nav__link"*/}
        {/*        activeClassName="nav__link--active"*/}
        {/*      >*/}
        {/*        {page.data.meta_navigation_title}*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*  )*/}
        {/*})}*/}
      </ul>
      <ul className="nav__list nav__list--secondary">
        <h4>Information</h4>
        <LegalPageNavigation />
        {data.allPrismicContactPage.nodes.map((page, index) => {
          return (
            <li className="nav__list-item" key={index}>
              <Link
                to="/contact"
                className="nav__link"
                activeClassName="nav__link--active"
              >
                {page.data.meta_navigation_title}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
export default FooterNavigation
