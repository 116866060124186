import Fuse from 'fuse.js'
import randomString from '../../helpers/random-string'
import { graphql, useStaticQuery } from 'gatsby'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import useIsMobile from '../../hooks/use-is-mobile'
import { Select } from 'crostamollica-components/assets'

const searchCompare = (a, b) => {
  if (a.score > b.score) {
    return 1
  }

  if (a.score < b.score) {
    return -1
  }

  return 0
}

const groupCompare = (a, b) => {
  const maxA = a.options.reduce((max, option) => Math.max(max, option.score), 0)
  const maxB = a.options.reduce((max, option) => Math.max(max, option.score), 0)

  if (maxA > maxB) {
    return 1
  }

  if (maxA < maxB) {
    return -1
  }

  return 0
}

const ProductAutocomplete = forwardRef(
  ({ productsProp, selectedProduct, onChange = () => {} }, ref) => {
    const uniqueId = useRef(randomString(12))
    const autocompleteRef = useRef(null)
    const resultsRef = useRef(null)
    const inputRef = useRef(null)
    const [products, setProducts] = useState([])
    const [groupedProducts, setGroupedProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [productSearchTerm, setProductSearchTerm] = useState('')
    const [autocompleteStyles, setAutocompleteStyles] = useState({})
    const [selectedProductName, setSelectedProductName] = useState('Product')
    const [autocompleteFocused, setAutocompleteFocused] = useState(false)
    const [expandedGroup, setExpandedGroup] = useState(null)
    const isMobile = useIsMobile()

    const data = useStaticQuery(graphql`query AutocompleteQuery {
  productTypes: allPrismicProductTypePage(sort: {data: {display_order: ASC}}) {
    nodes {
      uid
      data {
        meta_navigation_title
        product_type_name {
          text
        }
      }
    }
  }
  products: allPrismicProductPage {
    nodes {
      uid
      data {
        meta_navigation_title
        product_name {
          text
        }
        product_type {
          document {
            ... on PrismicProductTypePage {
              uid
            }
          }
        }
      }
    }
  }
}`)

    useEffect(() => {
      if (productsProp) {
        setProducts(productsProp)
        return
      }

      const groupedProducts = data?.productTypes?.nodes?.map(productType => ({
        ...productType,
        products: data?.products?.nodes?.filter(
          product => product.data.product_type.document.uid === productType.uid
        ),
      }))
      setProducts(groupedProducts)
    }, [productsProp, data, setProducts])

    const getResultsHeight = useCallback(() => {
      if (!resultsRef.current) {
        return 'none'
      }

      switch (true) {
        case autocompleteRef.current.getBoundingClientRect().top >
          window.innerHeight / 2:
          return (
            Math.max(
              300,
              Math.min(
                autocompleteRef.current.getBoundingClientRect().bottom,
                window.innerHeight
              ) - 50
            ) + 'px'
          )

        default:
          return (
            Math.max(
              300,
              Math.min(
                window.innerHeight -
                  autocompleteRef.current.getBoundingClientRect().top,
                window.innerHeight
              ) - 50
            ) + 'px'
          )
      }
    }, [resultsRef, autocompleteRef])

    useEffect(() => {
      if (!selectedProduct || selectedProduct === 'all') {
        setSelectedProductName('Product')
        return
      }

      const product = products.reduce((prev, group) => {
        if (group) {
          const item = group.products.reduce((prev, item) => {
            if (item.uid === selectedProduct) {
              return item
            }

            return prev
          }, null)

          if (item) {
            return item
          }

          return prev
        }
      }, null)

      if (!product) {
        setSelectedProductName('Product')
      }

      setSelectedProductName(product.data.product_name.text)
    }, [products, selectedProduct, setSelectedProductName])

    const handleScroll = useCallback(() => {
      if (!autocompleteRef.current) {
        return
      }

      switch (true) {
        case autocompleteRef.current.getBoundingClientRect().top >
          window.innerHeight / 2:
          setAutocompleteStyles({
            bottom: '100%',
            marginBottom: '1em',
            maxHeight: getResultsHeight(),
          })
          break

        default:
          setAutocompleteStyles({
            maxHeight: getResultsHeight(),
            top: '100%',
            marginTop: '1em',
          })
          break
      }
    }, [setAutocompleteStyles])

    useEffect(() => {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [handleScroll])

    useEffect(() => {
      setGroupedProducts(
        products
          ? products.map(
              ({
                data: { meta_navigation_title, product_type_name },
                products,
              }) => ({
                label: meta_navigation_title || product_type_name.text,
                options: products.map(
                  ({ data: { meta_navigation_title, product_name }, uid }) => ({
                    label: meta_navigation_title || product_name.text,
                    value: uid,
                  })
                ),
              })
            )
          : []
      )
    }, [products, setGroupedProducts])

    useEffect(() => {
      setFilteredProducts(
        !productSearchTerm
          ? groupedProducts
          : groupedProducts
              .map(group => {
                const fuse = new Fuse(group.options, {
                  includeScore: true,
                  threshold: 0.3,
                  keys: ['label'],
                })
                const result = fuse.search(productSearchTerm)
                return {
                  label: group.label,
                  options: result.sort(searchCompare).map(({ item }) => item),
                }
              })
              .sort(groupCompare)
      )
    }, [groupedProducts, productSearchTerm, setFilteredProducts])

    const filterSearchResults = useCallback(
      event => {
        setProductSearchTerm(event.target.value)
      },
      [setProductSearchTerm]
    )

    const handleClickOutside = useCallback(() => {
      setAutocompleteFocused(false)
    }, [setAutocompleteFocused])

    const handleClickInside = useCallback(event => {
      event.stopPropagation()
    }, [])

    useEffect(() => {
      if (autocompleteFocused) {
        document.addEventListener('click', handleClickOutside)
        autocompleteRef.current.addEventListener('click', handleClickInside)

        return () => {
          document.removeEventListener('click', handleClickOutside)
          autocompleteRef.current.removeEventListener(
            'click',
            handleClickInside
          )
        }
      }
    }, [autocompleteFocused, handleClickOutside, handleClickInside])

    const expandGroup = useCallback(
      event => {
        event.stopPropagation()
        event.preventDefault()
        const element = event.currentTarget || event.target
        const title = element.dataset.title

        setExpandedGroup(current => {
          if (current === title) {
            return null
          }

          return title
        })
      },
      [setExpandedGroup]
    )

    return (
      <div className="toolbar__autocomplete autocomplete" ref={autocompleteRef}>
        <input
          id="filter-products"
          name="products"
          className="toolbar__control autocomplete__control"
          value={selectedProductName}
          onChange={event => onChange(event.target.value)}
          onFocus={() => {
            inputRef.current && !isMobile && inputRef.current.focus()
            setAutocompleteFocused(true)
          }}
          ref={ref}
          readOnly
        />

        {selectedProduct !== 'all' && (
          <button
            type="button"
            className="autocomplete__clear"
            onClick={async event => onChange('all')}
          >
            <span className="screenreader-text">Remove filter</span>
          </button>
        )}

        <div
          className="autocomplete__results"
          aria-hidden={!autocompleteFocused}
          onClick={e => e.stopPropagation()}
          style={autocompleteStyles}
          ref={resultsRef}
        >
          <input
            className="autocomplete__search"
            ref={inputRef}
            type="search"
            value={productSearchTerm}
            placeholder="Search&hellip;"
            onInput={filterSearchResults}
            onFocus={() => setAutocompleteFocused(true)}
            aria-autocomplete="list"
            aria-owns={`autocomplete-list-${uniqueId.current}`}
            aria-activedescendant={`autocomplete-item-${selectedProduct}-${uniqueId.current}`}
          />

          <div
            className="autocomplete__results-list"
            id={`autocomplete-list-${uniqueId.current}`}
          >
            <button
              className="autocomplete__switch"
              type="button"
              role="checkbox"
              aria-checked={selectedProduct === 'all'}
              onPointerDown={() => {
                onChange('all')
                setTimeout(() => setAutocompleteFocused(false), 500)
              }}
              id={`autocomplete-item-all-${uniqueId.current}`}
            >
              All
            </button>

            {filteredProducts &&
              filteredProducts.map(
                (group, index) =>
                  group.options.length > 0 && (
                    <div key={index}>
                      <h5 className="autocomplete__group-title">
                        <button
                          className="autocomplete__group-title-button"
                          onPointerDown={expandGroup}
                          data-title={group.label}
                          aria-expanded={expandedGroup === group.label}
                          aria-controls={`autocomplete-group-${index}-${uniqueId.current}`}
                        >
                          {group.label}

                          <Select />
                        </button>
                      </h5>

                      <ul
                        className="autocomplete__group-items"
                        aria-hidden={expandedGroup !== group.label}
                        id={`autocomplete-group-${index}-${uniqueId.current}`}
                      >
                        {group.options.map((option, indexAlt) => (
                          <li className="toolbar__group-item" key={indexAlt}>
                            <button
                              className="autocomplete__switch"
                              type="button"
                              role="switch"
                              aria-checked={selectedProduct === option.value}
                              onPointerDown={() => {
                                onChange(option.value)
                                setTimeout(
                                  () => setAutocompleteFocused(false),
                                  500
                                )
                              }}
                              id={`autocomplete-item-${option.value}-${uniqueId.current}`}
                            >
                              {option.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    )
  }
)

export default ProductAutocomplete
