import React, { useCallback, useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ArticleSummary from './article-summary'
import qs from 'query-string'
import JournalToolbar from './journal-toolbar'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { animate } from '@superrb/gatsby-addons/utils'

const FILTER_MAP = {
  category: (article, category) =>
    !category ||
    category === 'all' ||
    article.data?.category?.document?.uid === category,
}

const ArticleListing = ({ articles }) => {
  const [filterFields, setFilterFields] = useState({
    category: 'all',
  })

  const listingRef = useRef(null)

  useEffect(() => {
    const queryString = qs.parse(window.location.search)
    setFilterFields({
      category: queryString.category || 'all',
    })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      categories: allPrismicJournalCategory {
        nodes {
          uid
          data {
            name
          }
        }
      }
    }
  `)

  const categories = data.categories.nodes

  const filterBy = useCallback(
    async (field, value) => {
      if (listingRef.current) {
        await animate(1, 0, t => {
          listingRef.current.style.opacity = t
        })
      }
      setFilterFields(fields => {
        // Clone object to force re-render
        const cloned = { ...fields }
        cloned[field] = value

        return cloned
      })

      const queryString = qs.parse(window.location.search)
      queryString[field] = value
      window.history.pushState(
        {},
        null,
        `${window.location.pathname}?${qs.stringify(queryString)}`
      )

      if (listingRef.current) {
        await animate(0, 1, t => {
          listingRef.current.style.opacity = t
        })
      }
    },
    [setFilterFields]
  )

  const filteredArticles = Object.keys(FILTER_MAP).reduce((articles, field) => {
    return articles.filter(article =>
      FILTER_MAP[field](article, filterFields[field])
    )
  }, articles)

  return (
    <section className="recipe-listing">
      <div className="recipe-listing__container container">
        <JournalToolbar
          categories={categories}
          selectedCategory={filterFields.category}
          onFilterChange={filterBy}
        />

        <ul className="recipe-listing__recipes" ref={listingRef}>
          {filteredArticles.length > 0 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 1024: 3 }}
            >
              <Masonry gutter={`${1.5714285714 * 1.5}em`}>
                {filteredArticles.map((article, index) => (
                  <ArticleSummary
                    className="recipe-listing__recipe"
                    article={article}
                    key={article.uid}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <p className="recipe-listing__empty">No recipes found</p>
          )}
        </ul>
      </div>
    </section>
  )
}

export default ArticleListing
