import { graphql } from 'gatsby'
import { RichText } from '@superrb/gatsby-addons/components'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Heading, Hreflang } from 'crostamollica-components/components'
import RecipePageHeader from '../components/recipes/recipe-page-header'
import RecipeSidebar from '../components/recipes/recipe-sidebar'
import Seo from '@superrb/gatsby-addons/src/components/seo'
import getContrastingTextColor from '../helpers/get-contrasting-text-color'
import { Image } from '@superrb/gatsby-addons/components'
import RelatedRecipes from '../components/recipes/related-recipes'
import { PrismicLink } from 'crostamollica-components/utils'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const RecipePageTemplate = ({ data, location, pageContext }) => {
  if (!data) return null

  const pageData = data.prismicRecipePage.data
  const relatedRecipes = data.relatedRecipes?.nodes
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <>
      {pageData.highlight_color && (
        <Helmet>
          <style>{`
          :root {
            --page-highlight-color: ${pageData.highlight_color};
            --page-highlight-text-color: ${getContrastingTextColor(
              pageData.highlight_color
            )};
          }
        `}</style>
        </Helmet>
      )}

      <Hreflang lang={process.env.GATSBY_LANGUAGE} />

      <Seo data={pageData} />

      <RecipePageHeader
        location={location}
        category={pageData.category.document}
        heading={pageData.title}
        author={pageData.author}
        title={pageData.meta_navigation_title}
        text={pageData.description}
        cookTime={pageData.cook_time}
        prepTime={pageData.prep_time}
        serves={pageData.serves}
        image={pageData.main_image}
        crumbs={crumbs}
      />

      <section className="recipe-detail">
        <div className="recipe-detail__container container container--flex">
          <RecipeSidebar recipe={pageData} />

          <div className="recipe-detail__content">
            {pageData.content_heading && (
              <Heading level={2} text={pageData.content_heading.text} />
            )}

            {pageData.content && (
              <div className="recipe-detail__content rte">
                <RichText field={pageData.content.richText} />
              </div>
            )}

            {(pageData.image_01 || pageData.image_02) && (
              <figure className="recipe-detail__images">
                {pageData.image_01 && (
                  <Image
                    image={pageData.image_01}
                    alt={pageData.image_01.alt}
                  />
                )}

                {pageData.image_02 && (
                  <Image
                    image={pageData.image_02}
                    alt={pageData.image_02.alt}
                  />
                )}
              </figure>
            )}

            <div className="recipe-detail__method">
              {pageData.method_heading && (
                <Heading
                  level={2}
                  text={pageData.method_heading.text}
                  className="recipe-detail__method-title"
                />
              )}

              <ul className="recipe-detail__method-steps">
                {pageData.method.map(({ step }, index) => (
                  <li key={index} className="recipe-detail__method-step">
                    <h4 className="recipe-detail__method-step-heading">
                      Step {index + 1}
                    </h4>
                    <RichText
                      field={step.richText}
                      className="recipe-detail__method-step-content"
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className="recipe-detail__content rte">
              <RichText field={pageData.bottom_content.richText} />
            </div>
          </div>
        </div>

        <RelatedRecipes recipes={relatedRecipes} />
      </section>
    </>
  )
}

export default withPrismicPreview(RecipePageTemplate)

export const query = graphql`query GetRecipe($slug: String!) {
  prismicRecipePage(uid: {eq: $slug}) {
    _previewable
    uid
    data {
      title {
        text
        richText
      }
      description {
        text
        richText
      }
      category {
        document {
          ... on PrismicRecipeCategory {
            uid
            data {
              name
            }
          }
        }
      }
      main_image {
        ...Image
      }
      content_heading {
        richText
        text
      }
      content {
        richText
        text
      }
      method_heading {
        richText
        text
      }
      method {
        step {
          richText
          text
        }
      }
      image_01 {
        ...Image
      }
      image_02 {
        ...Image
      }
      author
      cook_time
      prep_time
      serves
      ingredients {
        ingredient
      }
      featured_product {
        document {
          ... on PrismicProductPage {
            uid
            data {
              product_name {
                text
                richText
              }
              product_sub_heading {
                text
                richText
              }
              product_image_01 {
                ...Image
              }
              product_image_02 {
                ...Image
              }
              product_type {
                uid
              }
            }
          }
        }
      }
      bottom_content {
        text
        richText
      }
      meta_description
      meta_robots
      meta_title
      meta_navigation_title
      meta_image {
        ...Image
      }
    }
  }
  relatedRecipes: allPrismicRecipePage(
    limit: 3
    filter: {uid: {ne: $slug}}
    sort: {first_publication_date: DESC}
  ) {
    nodes {
      uid
      data {
        title {
          richText
          text
        }
        cook_time
        prep_time
        main_image {
          ...Image
        }
        category {
          document {
            ... on PrismicRecipeCategory {
              uid
              data {
                name
              }
            }
          }
        }
        featured_product {
          document {
            ... on PrismicProductPage {
              uid
            }
          }
        }
      }
    }
  }
}`
