import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Retailer from './retailer'

class ShopOnlineRetailers extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`query ShopOnlineRetailersQuery {
  allPrismicRetailer(
    filter: {tags: {eq: "Online"}}
    sort: {data: {display_order: ASC}}
  ) {
    nodes {
      data {
        display_order
        name {
          richText
        }
        website_address {
          url
          target
        }
        logo {
          ...Image
        }
      }
    }
  }
}`}
        render={data => (
          <section className="where-to-buy-stores-online">
            <div className="where-to-buy-stores-online__container">
              {data.allPrismicRetailer.nodes.map((retailer, index) => {
                return (
                  <Retailer retailer={retailer} type="online" key={index} />
                )
              })}
            </div>
          </section>
        )}
      />
    )
  }
}

export default ShopOnlineRetailers
